export const timezones = [
  {
    timezoneId: "Pacific/Midway",
    offset: "UTC-11:00",
    name: "Midway Island, American Samoa",
  },
  {
    timezoneId: "Pacific/Honolulu",
    offset: "UTC-10:00",
    name: "Hawaii",
  },
  {
    timezoneId: "America/Anchorage",
    offset: "UTC-08:00",
    name: "Alaska",
  },
  {
    timezoneId: "America/Tijuana",
    offset: "UTC-07:00",
    name: "Baja California",
  },
  {
    timezoneId: "America/Los_Angeles",
    offset: "UTC-07:00",
    name: "Pacific Time US and Canada)",
  },
  {
    timezoneId: "America/Phoenix",
    offset: "UTC-07:00",
    name: "Arizona",
  },
  {
    timezoneId: "America/Chihuahua",
    offset: "UTC-06:00",
    name: "Chihuahua, La Paz, Mazatlan",
  },
  {
    timezoneId: "America/Denver",
    offset: "UTC-06:00",
    name: "Mountain Time US and Canada)",
  },
  {
    timezoneId: "America/Belize",
    offset: "UTC-06:00",
    name: "Central America",
  },
  {
    timezoneId: "America/Chicago",
    offset: "UTC-05:00",
    name: "Central Time US and Canada)\t",
  },
  {
    timezoneId: "America/Mexico_City",
    offset: "UTC-05:00",
    name: "Guadalajara, Mexico City, Monterrey",
  },
  {
    timezoneId: "America/Regina",
    offset: "UTC-06:00",
    name: "Saskatchewan",
  },
  {
    timezoneId: "America/Bogota",
    offset: "UTC-05:00",
    name: "Bogota, Lima, Quito",
  },
  {
    timezoneId: "America/Jamaica",
    offset: "UTC-05:00",
    name: "Kingston, George Town",
  },
  {
    timezoneId: "America/New_York",
    offset: "UTC-04:00",
    name: "Eastern Time US and Canada)",
  },
  {
    timezoneId: "America/Indiana/Indianapolis",
    offset: "UTC-04:00",
    name: "Indiana East)",
  },
  {
    timezoneId: "America/Caracas",
    offset: "UTC-04:30",
    name: "Caracas",
  },
  {
    timezoneId: "America/Asuncion",
    offset: "UTC-03:00",
    name: "Asuncion",
  },
  {
    timezoneId: "America/Halifax",
    offset: "UTC-03:00",
    name: "Atlantic Time Canada)",
  },
  {
    timezoneId: "America/Cuiaba",
    offset: "UTC-04:00",
    name: "Cuiaba",
  },
  {
    timezoneId: "America/Manaus",
    offset: "UTC-04:00",
    name: "Georgetown, La Paz, Manaus, San Juan",
  },
  {
    timezoneId: "America/St_Johns",
    offset: "UTC-02:30",
    name: "Newfoundland and Labrador",
  },
  {
    timezoneId: "America/Sao_Paulo",
    offset: "UTC-03:00",
    name: "Brasilia",
  },
  {
    timezoneId: "America/Buenos_Aires",
    offset: "UTC-03:00",
    name: "Buenos Aires",
  },
  {
    timezoneId: "America/Cayenne",
    offset: "UTC-03:00",
    name: "Cayenne, Fortaleza",
  },
  {
    timezoneId: "America/Godthab",
    offset: "UTC-02:00",
    name: "Greenland",
  },
  {
    timezoneId: "America/Montevideo",
    offset: "UTC-03:00",
    name: "Montevideo",
  },
  {
    timezoneId: "America/Bahia",
    offset: "UTC-03:00",
    name: "Salvador",
  },
  {
    timezoneId: "America/Santiago",
    offset: "UTC-03:00",
    name: "Santiago",
  },
  {
    timezoneId: "America/Noronha",
    offset: "UTC-02:00",
    name: "Mid-Atlantic",
  },
  {
    timezoneId: "Atlantic/Azores",
    offset: "UTC+00:00",
    name: "Azores",
  },
  {
    timezoneId: "Atlantic/Cape_Verde",
    offset: "UTC-01:00",
    name: "Cape Verde Islands",
  },
  {
    timezoneId: "Europe/London",
    offset: "UTC+01:00",
    name: "Dublin, Edinburgh, Lisbon, London",
  },
  {
    timezoneId: "Africa/Casablanca",
    offset: "UTC+01:00",
    name: "Casablanca",
  },
  {
    timezoneId: "Africa/Monrovia",
    offset: "UTC+00:00",
    name: "Monrovia, Reykjavik",
  },
  {
    timezoneId: "Europe/Amsterdam",
    offset: "UTC+02:00",
    name: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  {
    timezoneId: "Europe/Belgrade",
    offset: "UTC+02:00",
    name: "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    timezoneId: "Europe/Brussels",
    offset: "UTC+02:00",
    name: "Brussels, Copenhagen, Madrid, Paris",
  },
  {
    timezoneId: "Europe/Warsaw",
    offset: "UTC+02:00",
    name: "Sarajevo, Skopje, Warsaw, Zagreb",
  },
  {
    timezoneId: "Africa/Algiers",
    offset: "UTC+01:00",
    name: "West Central Africa",
  },
  {
    timezoneId: "Africa/Windhoek",
    offset: "UTC+02:00",
    name: "Windhoek",
  },
  {
    timezoneId: "Europe/Athens",
    offset: "UTC+03:00",
    name: "Athens, Bucharest",
  },
  {
    timezoneId: "Asia/Beirut",
    offset: "UTC+03:00",
    name: "Beirut",
  },
  {
    timezoneId: "Africa/Cairo",
    offset: "UTC+02:00",
    name: "Cairo",
  },
  {
    timezoneId: "Asia/Damascus",
    offset: "UTC+03:00",
    name: "Damascus",
  },
  {
    timezoneId: "EET",
    offset: "UTC+03:00",
    name: "Eastern Europe",
  },
  {
    timezoneId: "Africa/Harare",
    offset: "UTC+02:00",
    name: "Harare, Pretoria",
  },
  {
    timezoneId: "Europe/Helsinki",
    offset: "UTC+03:00",
    name: "Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
  },
  {
    timezoneId: "Asia/Istanbul",
    offset: "UTC+03:00",
    name: "Istanbul",
  },
  {
    timezoneId: "Asia/Jerusalem",
    offset: "UTC+03:00",
    name: "Jerusalem",
  },
  {
    timezoneId: "Europe/Kaliningrad",
    offset: "UTC+02:00",
    name: "Kaliningrad",
  },
  {
    timezoneId: "Africa/Tripoli",
    offset: "UTC+02:00",
    name: "Tripoli",
  },
  {
    timezoneId: "Asia/Amman",
    offset: "UTC+03:00",
    name: "Amman",
  },
  {
    timezoneId: "Asia/Baghdad",
    offset: "UTC+03:00",
    name: "Baghdad",
  },
  {
    timezoneId: "Asia/Kuwait",
    offset: "UTC+03:00",
    name: "Kuwait, Riyadh",
  },
  {
    timezoneId: "Europe/Minsk",
    offset: "UTC+03:00",
    name: "Minsk",
  },
  {
    timezoneId: "Europe/Moscow",
    offset: "UTC+03:00",
    name: "Moscow, St. Petersburg, Volgograd",
  },
  {
    timezoneId: "Africa/Nairobi",
    offset: "UTC+03:00",
    name: "Nairobi",
  },
  {
    timezoneId: "Asia/Tehran",
    offset: "UTC+03:30",
    name: "Tehran",
  },
  {
    timezoneId: "Asia/Muscat",
    offset: "UTC+04:00",
    name: "Abu Dhabi, Muscat",
  },
  {
    timezoneId: "Asia/Baku",
    offset: "UTC+05:00",
    name: "Baku",
  },
  {
    timezoneId: "Europe/Samara",
    offset: "UTC+04:00",
    name: "Izhevsk, Samara",
  },
  {
    timezoneId: "Indian/Mauritius",
    offset: "UTC+04:00",
    name: "Port Louis",
  },
  {
    timezoneId: "Asia/Tbilisi",
    offset: "UTC+04:00",
    name: "Tbilisi",
  },
  {
    timezoneId: "Asia/Yerevan",
    offset: "UTC+04:00",
    name: "Yerevan",
  },
  {
    timezoneId: "Asia/Kabul",
    offset: "UTC+04:30",
    name: "Kabul",
  },
  {
    timezoneId: "Asia/Tashkent",
    offset: "UTC+05:00",
    name: "Tashkent, Ashgabat",
  },
  {
    timezoneId: "Asia/Yekaterinburg",
    offset: "UTC+05:00",
    name: "Ekaterinburg",
  },
  {
    timezoneId: "Asia/Karachi",
    offset: "UTC+05:00",
    name: "Islamabad, Karachi",
  },
  {
    timezoneId: "Asia/Kolkata",
    offset: "UTC+05:30",
    name: "Chennai, Kolkata, Mumbai, New Delhi",
  },
  {
    timezoneId: "Asia/Colombo",
    offset: "UTC+05:30",
    name: "Sri Jayawardenepura",
  },
  {
    timezoneId: "Asia/Katmandu",
    offset: "UTC+05:45",
    name: "Kathmandu",
  },
  {
    timezoneId: "Asia/Almaty",
    offset: "UTC+06:00",
    name: "Astana",
  },
  {
    timezoneId: "Asia/Dhaka",
    offset: "UTC+06:00",
    name: "Dhaka",
  },
  {
    timezoneId: "Asia/Novosibirsk",
    offset: "UTC+06:00",
    name: "Novosibirsk",
  },
  {
    timezoneId: "Asia/Rangoon",
    offset: "UTC+06:30",
    name: "Yangon Rangoon)",
  },
  {
    timezoneId: "Asia/Bangkok",
    offset: "UTC+07:00",
    name: "Bangkok, Hanoi, Jakarta",
  },
  {
    timezoneId: "Asia/Krasnoyarsk",
    offset: "UTC+07:00",
    name: "Krasnoyarsk",
  },
  {
    timezoneId: "Asia/Chongqing",
    offset: "UTC+08:00",
    name: "Beijing, Chongqing, Hong Kong SAR, Urumqi",
  },
  {
    timezoneId: "Asia/Irkutsk",
    offset: "UTC+08:00",
    name: "Irkutsk",
  },
  {
    timezoneId: "Asia/Kuala_Lumpur",
    offset: "UTC+08:00",
    name: "Kuala Lumpur, Singapore",
  },
  {
    timezoneId: "Australia/Perth",
    offset: "UTC+08:00",
    name: "Perth",
  },
  {
    timezoneId: "Asia/Taipei",
    offset: "UTC+08:00",
    name: "Taipei",
  },
  {
    timezoneId: "Asia/Ulaanbaatar",
    offset: "UTC+08:00",
    name: "Ulaanbaatar",
  },
  {
    timezoneId: "Asia/Tokyo",
    offset: "UTC+09:00",
    name: "Osaka, Sapporo, Tokyo",
  },
  {
    timezoneId: "Asia/Seoul",
    offset: "UTC+09:00",
    name: "Seoul",
  },
  {
    timezoneId: "Asia/Yakutsk",
    offset: "UTC+09:00",
    name: "Yakutsk",
  },
  {
    timezoneId: "Australia/Adelaide",
    offset: "UTC+10:30",
    name: "Adelaide",
  },
  {
    timezoneId: "Australia/Darwin",
    offset: "UTC+09:30",
    name: "Darwin",
  },
  {
    timezoneId: "Australia/Brisbane",
    offset: "UTC+10:00",
    name: "Brisbane",
  },
  {
    timezoneId: "Australia/Canberra",
    offset: "UTC+11:00",
    name: "Canberra, Melbourne, Sydney",
  },
  {
    timezoneId: "Pacific/Guam",
    offset: "UTC+10:00",
    name: "Guam, Port Moresby",
  },
  {
    timezoneId: "Australia/Hobart",
    offset: "UTC+11:00",
    name: "Hobart",
  },
  {
    timezoneId: "Asia/Magadan",
    offset: "UTC+10:00",
    name: "Magadan",
  },
  {
    timezoneId: "Asia/Vladivostok",
    offset: "UTC+10:00",
    name: "Vladivostok, Magadan",
  },
  {
    timezoneId: "Asia/Srednekolymsk",
    offset: "UTC+11:00",
    name: "Chokirdakh",
  },
  {
    timezoneId: "Pacific/Guadalcanal",
    offset: "UTC+11:00",
    name: "Solomon Islands, New Caledonia",
  },
  {
    timezoneId: "Asia/Anadyr",
    offset: "UTC+12:00",
    name: "Anadyr, Petropavlovsk-Kamchatsky",
  },
  {
    timezoneId: "Pacific/Auckland",
    offset: "UTC+13:00",
    name: "Auckland, Wellington",
  },
  {
    timezoneId: "Pacific/Fiji",
    offset: "UTC+12:00",
    name: "Fiji Islands, Kamchatka, Marshall Islands",
  },
  {
    timezoneId: "Pacific/Tongatapu",
    offset: "UTC+13:00",
    name: "Nuku'alofa",
  },
  {
    timezoneId: "Pacific/Apia",
    offset: "UTC+14:00",
    name: "Samoa",
  },
];
