import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { ClassNames } from "@emotion/react";
import { AUTH_NAMESPACE } from "auth/locales";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoIcon } from "../static/Logo.svg";
import BackgroundImage from "../static/LoginBackground.png";

interface Props {
  onChange: (state: string) => void;
}

export const SignIn: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation(AUTH_NAMESPACE);

  return (
    <Flex
      flexDirection={["column", null, null, "row"]}
      minHeight="100vh"
      backgroundColor="primary.100"
      backgroundImage={["initial", null, null, `url(${BackgroundImage})`]}
      backgroundSize="60%">
      <Flex
        width={["100%", null, null, "50%"]}
        p={[10, null, null, 0]}
        alignItems="center"
        justifyContent="center">
        <LogoIcon width="40%" height="auto" />
      </Flex>
      <Flex
        width={["100%", null, null, "50%"]}
        flexGrow={[1, null, null, 0]}
        alignItems={["flex-start", null, null, "center"]}
        justifyContent="center"
        bg="white"
        p={[10, null, null, 0]}
        borderRadius={["16px 16px 0 0", null, null, "16px 0 0 16px"]}>
        <Box>
          <Heading variant="h2" color="primary.100" textTransform="uppercase">
            {t("login")}
          </Heading>
          <ClassNames>
            {({ css, cx }) => (
              <AmplifyAuthenticator
                handleAuthStateChange={(state: string, _: any) =>
                  onChange(state)
                }
                className={css`
                  --amplify-primary-color: #dc0000;
                  --amplify-primary-tint: #dc0000;
                  --amplify-primary-shade: #dc0000;
                  --box-shadow: none;
                  --container-justify: initial;
                  --container-align: initial;
                  --container-height: 0;
                  --padding: 0;
                `}>
                <AmplifySignIn
                  headerText=""
                  slot="sign-in"
                  hideSignUp={true}
                  submitButtonText="LOG IN"
                  formFields={[{ type: "email" }, { type: "password" }]}
                />
              </AmplifyAuthenticator>
            )}
          </ClassNames>
        </Box>
      </Flex>
    </Flex>
  );
};
