import { Box } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import {
  LineChart as RechartsLineChart,
  Line,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  ReferenceLine,
  YAxis,
} from "recharts";

export type LineType = "monotone" | "stepAfter";

export interface TimeSeriesChartPoint {
  timestamp: number;
  [dataKey: string]: number;
}

export interface TimeSeriesChartConfig {
  dataKey: string;
  color: string;
  label: string;
  lineType?: LineType;
}

interface Props {
  data: TimeSeriesChartPoint[];
  bars: TimeSeriesChartConfig[];
}

export const TimeSeriesChart: React.FC<Props> = ({ data, bars }) => {
  if (data.length === 0) {
    return null;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsLineChart
        data={data}
        margin={{
          top: 10,
          right: 3,
          left: 3,
          bottom: 10,
        }}>
        <Tooltip
          contentStyle={{ textAlign: "center" }}
          labelFormatter={(timestamp) =>
            moment(timestamp).format("HH:mm:ss, MMM DD YYYY")
          }
        />
        <XAxis
          dataKey="timestamp"
          type="number"
          height={0}
          domain={["auto", "auto"]}
          scale="time"
        />
        <YAxis
          type="number"
          tickFormatter={(value) =>
            isNaN(value) ? "" : Math.round(value).toString()
          }
          width={1}
          tickMargin={-30}
          tickSize={0}
          tickCount={3}
          tickLine={false}
          axisLine={false}
          interval="preserveStartEnd"
          domain={[
            (dataMin: number) => dataMin - dataMin * 0.05,
            (dataMax: number) => dataMax + dataMax * 0.05,
          ]}
        />
        {data.length === 1
          ? bars.map((bar, index) => (
              <ReferenceLine key={index} y={data[0].value} stroke={bar.color} />
            ))
          : bars.map((bar, index) => (
              <Line
                key={index}
                type={bar.lineType ?? "monotone"}
                dataKey={bar.dataKey}
                stroke={bar.color}
                isAnimationActive={false}
                name={bar.label}
                dot={(props) => {
                  const showDot = props.payload[`${props.dataKey}-real-value`];
                  if (showDot) {
                    return (
                      <circle
                        key={props.key}
                        r={1}
                        cx={props.cx}
                        cy={props.cy}
                        stroke={props.stroke}
                        fill={props.fill}
                        type={props.type}
                        name={props.name}
                        strokeWidth={props.strokeWidth}
                        width={props.width}
                        height={props.height}
                      />
                    );
                  } else {
                    return <Box as="span" key={props.key} />;
                  }
                }}
              />
            ))}
      </RechartsLineChart>
    </ResponsiveContainer>
  );
};
