import { Select, SelectProps } from "@chakra-ui/react";
import React from "react";
import { timezones } from "./models/timezones";

interface Props extends SelectProps {}

export const TimezoneSelect: React.FC<Props> = ({ defaultValue, ...rest }) => (
  <Select {...rest}>
    {timezones.map((timezone) => (
      <option key={timezone.timezoneId} value={timezone.timezoneId}>
        {timezone.timezoneId} {timezone.offset} ({timezone.name})
      </option>
    ))}
  </Select>
);
