import { AppModule } from "AppModule";
import { registerResourceBundle, SUPPORTED_LANGUAGES } from "i18n";
import { USERS_NAMESPACE } from "./locales";
import { default as EN } from "./locales/en.json";

export { default as NotificationsList } from "./components/NotificationsList";
export { default as NotificationCounterButton } from "./components/NotificationCounterButton";
export { default as Profile } from "./components/Profile";

export class UsersModule implements AppModule {
  setupTranslations() {
    registerResourceBundle(SUPPORTED_LANGUAGES.en, USERS_NAMESPACE, EN);
  }
}
