const Button = {
  variants: {
    primary: {
      color: "white",
      bg: "primary.100",
      fontSize: ["sm", "sm", "md", "md"],
      fontWeight: 600,
      borderRadius: "default",
      "&:hover": {
        bg: "primary.200",
      },
      "&:active": {
        bg: "primary.300",
      },
      "&:disabled": {
        bg: "disabled.100",
      },
    },
    secondary: {
      color: "secondary.200",
      bg: "secondary.700",
      fontSize: ["sm", "sm", "md", "md"],
      fontWeight: 600,
      borderRadius: "default",
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

export default Button;
