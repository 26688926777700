import UserStore from "app/users/stores/UserStore";
import React, { useContext } from "react";
import { MachineStore } from "../app/machines/stores";

interface StoreContextProps {
  machineStore: MachineStore;
  userStore: UserStore;
}

export const StoreContext = React.createContext<StoreContextProps>(
  {} as StoreContextProps
);

export const useStoreContext = () => useContext(StoreContext);
