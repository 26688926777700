import * as Yup from "yup";

export interface NotificationsCount {
  unread: number;
  total: number;
}

export interface Notification {
  id: number;
  createdAt: string;
  userId: string;
  read: boolean;
  deleted: boolean;
  subject: string;
  shortDescription?: string;
  type: "Informational" | "Entity";
  entity: "Machine";
  entityId?: string;
}

export interface NotificationChannel {
  type: "Email" | "InternalNotification" | "SMS";
  enabled: boolean;
}

export interface NotificationSetting {
  groupKey: string;
  channels: NotificationChannel[];
}

export interface UserProfile {
  id: number;
  email: string;
  phoneNumber?: string;
  timezoneId: string;
}

export interface UpdateUserProfileCommand {
  phoneNumber: string;
  timezoneId: string;
}

export interface ProfileForm {
  phoneNumber: string;
  timezoneId: string;
}

export const ProfileFormValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(
      /^\+[1-9]\d{1,14}$/,
      "Phone number must be in E.164 format, e.g. +351961234567"
    )
    .required("Required"),
  timezoneId: Yup.string().min(1, "Too Short!").required("Required"),
});
