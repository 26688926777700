import Axios from "axios";
import environment from "configurations";
import Auth from "@aws-amplify/auth";

// Create axios instances
const configuration = {
  baseURL: `${environment.api}`,
  headers: {
    "Content-Type": "application/json",
  },
};

export const axios = Axios.create(configuration);

// Interceptor that fills authorization token
axios.interceptors.request.use(
  (config) => {
    return new Promise(async (resolve) => {
      const currentSession = await Auth.currentSession();

      if (currentSession) {
        config.headers.Authorization = `Bearer ${currentSession
          .getIdToken()
          .getJwtToken()}`;
        return resolve(config);
      } else {
        return resolve(config);
      }
    });
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default axios;
