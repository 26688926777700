import { Box, Heading, IconButton } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { Link as ReactLink } from "react-router-dom";
import { useStoreContext } from "store/context";
import { setIntervalImmediately } from "utils";
import { ReactComponent as BellIcon } from "../static/Bell.svg";

const NotificationCounterButton = () => {
  const { userStore } = useStoreContext();

  useEffect(() => {
    let errors = 0;
    let timer = setIntervalImmediately(async () => {
      try {
        await userStore.getUnread();
        errors = 0;
      } catch (err) {
        console.error(err);
        errors += 1;

        if (timer && errors > 5) {
          clearInterval(timer);
        }
      }
    }, 30 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [userStore]);

  return (
    <Box position="relative">
      <IconButton
        as={ReactLink}
        variant="ghost"
        aria-label="Real time"
        icon={<BellIcon />}
        to="/notifications"
        onClick={() => setTimeout(() => userStore.readNotifications(), 5000)}
      />
      {userStore.unread > 0 && (
        <Heading
          variant="h7"
          bg="primary.100"
          color="white"
          borderRadius="50%"
          px="4px"
          py="2px"
          position="absolute"
          top={1}
          right={userStore.unread > 9 ? 0 : 1}>
          {userStore.unread > 9 ? "9+" : userStore.unread}
        </Heading>
      )}
    </Box>
  );
};

export default observer(NotificationCounterButton);
