import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import Button from "./Button";
import Divider from "./Divider";
import Heading from "./Heading";
import Select from "./Select";
import Input from "./Input";

/**
 * For now the layout has two modes: mobile and desktop. The breakpoint is a 768px
 */
const breakpoints = createBreakpoints({
  sm: "480px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1440px",
});

/**
 * Configures the theme based on the definitions exposed in sketch
 * Colors: https://www.sketch.com/s/e2f2c3a4-3da2-44cb-85b4-2217e1cd83d9/a/eKoeJp0
 *
 */
const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        backgroundColor: "secondary.600",
      },
    },
  },
  breakpoints,
  fonts: {
    heading: "Chevin Thin",
    body: "Chevin Thin",
  },
  fontSizes: {
    xs: "11px",
    sm: "13px",
    md: "15px",
    lg: "20px",
    xl: "32px",
  },
  colors: {
    white: { 100: "#FFFFFF" },
    background: { 100: "#FFFFFF" },
    primary: {
      100: "#DC0000",
      200: "#CA0000",
      300: "#B80000",
    },
    secondary: {
      100: "#333333",
      200: "#828282",
      300: "#B5B5B5",
      400: "#D6D6D6",
      500: "#E0E0E0",
      600: "#F0F0F0",
      700: "#F9F9F9",
    },
    disabled: {
      100: "#FEEBEB",
    },
    feedback: {
      positive: {
        100: "#57CC64",
        200: "#4EB759",
        300: "#45A350",
      },
      negative: {
        100: "#DC0000",
      },
      alert: {
        100: "#FA9900",
        200: "#E08900",
        300: "#C87A00",
      },
    },
    chart: {
      primary: {
        100: "#0071FF",
        200: "#7FB8FF",
      },
      secondary: {
        100: "#F37AD7",
        200: "#F9BCEB ",
      },
    },
    border: {
      active: {
        100: "#333333",
      },
      error: {
        100: "#DC0000",
      },
    },
  },
  radii: {
    none: "0",
    default: "8px",
  },
  sizes: {
    container: {
      full: "100wh",
    },
  },
  components: {
    Heading,
    Button,
    Divider,
    Select,
    Input,
  },
});

export default theme;
