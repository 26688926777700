import { Flex, Box, BoxProps, Tooltip, Text } from "@chakra-ui/react";
import { getDurationPresentationValue } from "app/machines/utils";
import moment from "moment";
import React from "react";

export interface IntervalChartPoint {
  startDate: string;
  endDate: string;
  type: string;
  color?: string;
  order?: number;
}

export interface Props extends BoxProps {
  beginDate: string;
  endDate: string;
  intervals: IntervalChartPoint[];
}

export const IntervalChart: React.FC<Props> = ({
  beginDate,
  endDate,
  intervals,
  ...rest
}) => {
  if (intervals.length === 0) {
    return null;
  }
  const fillIntervals = (
    data: IntervalChartPoint[],
    beginDate: string,
    endDate: string
  ): IntervalChartPoint[] => {
    if (data.length === 0) return [];
    const intervals = [...data];

    if (data[0].startDate !== beginDate) {
      intervals.push({
        startDate: beginDate,
        endDate: data[0].startDate,
        type: "none",
        order: 0,
      });
    }
    if (data[data.length - 1].endDate !== endDate) {
      intervals.push({
        startDate: data[data.length - 1].endDate,
        endDate: endDate,
        type: "none",
        order: 0,
      });
    }

    return intervals.sort((a, b) => a.startDate.localeCompare(b.startDate));
  };
  const totalTime = new Date(endDate).getTime() - new Date(beginDate).getTime();

  return (
    <Box background="secondary.300" height="15px" position="relative" {...rest}>
      {intervals
        .map((it) => it.type) // group by type
        .filter((v, i, a) => a.indexOf(v) === i) // filter unique
        .map((type) => (
          <Flex key={type} position="absolute" width="100%" height="100%">
            {fillIntervals(
              intervals.filter((it) => it.type === type),
              beginDate,
              endDate
            ).map((interval, index) => {
              const width =
                (new Date(interval.endDate).getTime() -
                  new Date(interval.startDate).getTime()) /
                totalTime;
              const timeDiff = getDurationPresentationValue(
                new Date(interval.endDate).getTime() -
                  new Date(interval.startDate).getTime()
              );
              const label = (
                <Text as="span">
                  {interval.color !== undefined ? `${type} - ` : ""}
                  {timeDiff}
                  <br />
                  {moment(interval.startDate).format("YYYY-MM-DD HH:mm:ss")}
                  <br />
                  {moment(interval.endDate).format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              );
              return (
                <Tooltip key={index} label={label} placement="left-end">
                  <Box
                    width={`${width * 100}%`}
                    background={interval.color}
                    zIndex={
                      interval.color !== undefined
                        ? (interval.order ?? 0) + 1
                        : undefined
                    }
                  />
                </Tooltip>
              );
            })}
          </Flex>
        ))}
    </Box>
  );
};
