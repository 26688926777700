const Heading = {
  variants: {
    h2: {
      color: "secondary.100",
      fontSize: ["xl", "xl", "xl", "xl"],
      weight: "300",
    },
    h3: {
      color: "secondary.100",
      fontSize: ["28px", "28px", "28px", "28px"],
      weight: "300",
    },
    h4: {
      color: "secondary.200",
      fontSize: ["lg", "lg", "lg", "lg"],
      weight: "500",
    },
    h5: {
      color: "secondary.200",
      fontSize: ["md", "md", "md", "md"],
      weight: "500",
    },
    h5Strong: {
      color: "secondary.100",
      fontSize: ["md", "md", "md", "md"],
      weight: "600",
    },
    h6: {
      color: "secondary.200",
      fontSize: ["sm", "sm", "sm", "sm"],
      weight: "600",
    },
    h7: {
      color: "secondary.200",
      fontSize: ["xs", "xs", "xs", "xs"],
      weight: "500",
    },
    h7Strong: {
      color: "secondary.100",
      fontSize: ["xs", "xs", "xs", "xs"],
      weight: "600",
    },
  },
  defaultProps: {
    variant: "h5",
  },
};

export default Heading;
