import axios from "network";
import { PageRequest, PageResponse } from "network/models";
import { stringifyPageRequest } from "network/utils";
import {
  MachineAnalyticsDTO,
  MachineCutAnalyticsDTO,
  MachineInstance,
  MachineInstanceListItem,
  MachineLog,
  MachineWorkItemAnalyticsDTO,
  MeasureList,
} from "../models";

export const getMachineInstances = async (
  pageRequest?: PageRequest
): Promise<PageResponse<MachineInstanceListItem>> => {
  return (
    await axios.get<PageResponse<MachineInstanceListItem>>(
      `/machineInstance${stringifyPageRequest(pageRequest)}`
    )
  ).data;
};

export const getMachineInstance = async (
  machineInstanceId: string
): Promise<MachineInstance> => {
  return (await axios.get(`/machineInstance/${machineInstanceId}`)).data;
};

export const getMachineInstanceSensorData = async (
  machineInstanceId: string,
  pageRequest?: PageRequest
): Promise<MeasureList[]> => {
  return (
    await axios.get(
      `/machineInstance/${machineInstanceId}/sensor${stringifyPageRequest(
        pageRequest
      )}`
    )
  ).data;
};

export const stopMachineInstance = (
  machineInstanceId: string
): Promise<void> => {
  return axios.post(`/machineInstance/${machineInstanceId}/stop`);
};

export const getMachineLogs = async (
  pageRequest?: PageRequest
): Promise<PageResponse<MachineLog>> => {
  return (
    await axios.get<PageResponse<MachineLog>>(
      `/machineLog${stringifyPageRequest(pageRequest)}`
    )
  ).data;
};

export const getMachineWeeklyReport = async (
  machineInstanceId: string,
  pageRequest?: PageRequest
): Promise<any> => {
  return await axios.get<PageResponse<MachineLog>>(
    `/reports/${machineInstanceId}${stringifyPageRequest(pageRequest)}`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getMachineMonthlyReport = async (
  pageRequest?: PageRequest
): Promise<any> => {
  return await axios.get<PageResponse<MachineLog>>(
    `/reports${stringifyPageRequest(pageRequest)}`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getMachineAnalytics = async (
  pageRequest?: PageRequest
): Promise<MachineAnalyticsDTO> => {
  return (
    await axios.get<MachineAnalyticsDTO>(
      `/workSessions${stringifyPageRequest(pageRequest)}`
    )
  ).data;
};

export const getMachineCutDetails = async (
  cutId: string
): Promise<MachineCutAnalyticsDTO> => {
  return (
    await axios.get<MachineCutAnalyticsDTO>(`/workSessions/cuts/${cutId}`)
  ).data;
};

export const getMachineWireDetails = async (
  wireId: string,
  pageRequest?: PageRequest
): Promise<MachineWorkItemAnalyticsDTO> => {
  return (
    await axios.get<MachineWorkItemAnalyticsDTO>(
      `/workSessions/wires/${wireId}${stringifyPageRequest(pageRequest)}`
    )
  ).data;
};
