import { makeAutoObservable, runInAction } from "mobx";
import { FilterField, SortField } from "network/models";
import {
  MachineInstance,
  MachineInstanceListItem,
  MachineLog,
} from "../models";
import {
  getMachineInstances,
  getMachineInstance,
  stopMachineInstance,
  getMachineLogs,
} from "../services";

export const LOCAL_STORAGE_DEFAULT_MACHINE_ID = "defaultMachineInstanceId";

export default class MachineStore {
  machineInstances: MachineInstanceListItem[] = [];
  machineLogs: MachineLog[] = [];
  totalInstances = 0;
  totalLogs = 0;
  machineInstance?: MachineInstance;
  defaultMachineInstanceId?: string = this.loadDefaultMachineInstanceId();
  state: "pending" | "done" | "error" = "done";
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  async wrapRequest<T>(body: () => Promise<T>): Promise<T> {
    try {
      this.state = "pending";
      this.error = "";
      const res = await body();
      runInAction(() => {
        this.state = "done";
      });
      return res;
    } catch (err) {
      runInAction(() => {
        this.state = "error";
        this.error = (err as any).message;
      });
      throw err;
    }
  }

  async getMachineInstances(
    page: number,
    size: number,
    filters: FilterField[],
    sorts: SortField[]
  ): Promise<MachineInstanceListItem[]> {
    return this.wrapRequest(async () => {
      const { meta, data } = await getMachineInstances({
        page,
        size,
        filters,
        sorts,
      });
      runInAction(() => {
        this.machineInstances = data;
        this.totalInstances = meta.totalElements;
      });
      return data;
    });
  }

  async getMachineInstance(
    machineInstanceId: string
  ): Promise<MachineInstance> {
    return this.wrapRequest(async () => {
      const machineInstance = await getMachineInstance(machineInstanceId);
      runInAction(() => {
        this.machineInstance = {
          ...machineInstance,
          machineDefinition: {
            ...machineInstance.machineDefinition,
            telemetry: machineInstance.machineDefinition.telemetry.sort(
              (a, b) => a.name.localeCompare(b.name)
            ),
          },
        };
      });
      return machineInstance;
    });
  }

  stopMachineInstance(machineInstanceId: string): Promise<void> {
    return stopMachineInstance(machineInstanceId);
  }

  async getMachineLogs(
    page: number,
    size: number,
    filters: FilterField[],
    sorts: SortField[]
  ): Promise<MachineLog[]> {
    return this.wrapRequest(async () => {
      const { meta, data } = await getMachineLogs({
        page,
        size,
        filters,
        sorts,
      });
      runInAction(() => {
        this.machineLogs = data;
        this.totalLogs = meta.totalElements;
      });
      return data;
    });
  }

  setDefaultMachineInstanceId(machineInstanceId: string): string {
    runInAction(() => {
      localStorage.setItem(LOCAL_STORAGE_DEFAULT_MACHINE_ID, machineInstanceId);
      this.defaultMachineInstanceId = machineInstanceId;
    });

    return machineInstanceId;
  }

  private loadDefaultMachineInstanceId(): string | undefined {
    return localStorage.getItem(LOCAL_STORAGE_DEFAULT_MACHINE_ID) ?? undefined;
  }
}
