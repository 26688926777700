import React from "react";
import { Panel } from "app/shared";
import { useTranslation } from "react-i18next";
import { USERS_NAMESPACE } from "../locales";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
} from "@chakra-ui/react";
import NotificationSettings from "./NotificationSettings";
import { AuthSettings } from "auth/components/AuthSettings";
import ProfileSettings from "./ProfileSettings";

const Profile: React.FC = () => {
  const { t } = useTranslation(USERS_NAMESPACE);

  return (
    <Panel maxWidth={["100%", "55%"]} mx="auto">
      <Tabs align="center">
        <TabList>
          <Tab _selected={{ borderColor: "#DC0000" }} textTransform="uppercase">
            {t("profile.account")}
          </Tab>
          <Tab _selected={{ borderColor: "#DC0000" }} textTransform="uppercase">
            {t("profile.notifications")}
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <AuthSettings />
          </TabPanel>
          <TabPanel>
            <NotificationSettings />
            <Divider my={3} />
            <ProfileSettings />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Panel>
  );
};

export default Profile;
