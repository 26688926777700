import * as Yup from "yup";

export interface AuthAttributes {
  email: string;
  sub: string;
  "custom:role": string;
  "custom:clientId": string;
}

export interface PasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const PasswordFormValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().min(8, "Too Short!").required("Required"),
  newPassword: Yup.string().min(8, "Too Short!").required("Required"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Required"),
});
