import { Box, Button, Heading, Link, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  MachineWorkItemAnalyticsDTO,
  MachineWorkItemSession,
} from "app/machines/models";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { tableStyle } from "app/shared/tables/style";
import { Column } from "primereact/column";
import { ClassNames } from "@emotion/react";
import {
  getDatePresentationValue,
  getPositionPresentationValue,
} from "../utils";
import { observer } from "mobx-react";
import { getMachineWireDetails } from "../services";
import { FilterField } from "network/models";

interface Props {
  wireId: string;
  startDate: string;
  endDate: string;
  openCutDetails: (cutId: string) => void;
}

const AnalyticWireDetails: React.FC<Props> = ({
  wireId,
  startDate,
  endDate,
  openCutDetails,
}) => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [wireAnalytics, setWireAnalytics] = useState<
    MachineWorkItemAnalyticsDTO | undefined
  >();
  const [wireCutsFilters, setWireCutsFilters] = useState<FilterField[]>([
    { field: "startDate", values: [startDate] },
    { field: "endDate", values: [endDate] },
  ]);

  useEffect(() => {
    getMachineWireDetails(wireId, {
      page: 0,
      size: 100,
      filters: wireCutsFilters,
    }).then((data) => setWireAnalytics(data));
  }, [wireId, wireCutsFilters]);

  const getPositionGroupValues = (
    workItems: MachineWorkItemSession[]
  ): string[] => {
    const positions = workItems.reduce(
      (group: Record<string, MachineWorkItemSession[]>, workItem) => {
        const category = getPositionPresentationValue(
          workItem.cutType,
          workItem.cutPosition
        );
        group[category] = group[category] ?? [];
        group[category].push(workItem);
        return group;
      },
      {}
    );
    const arr: string[] = [];
    Object.keys(positions).forEach((key) => {
      if (key !== "-") {
        const items = positions[key];
        const percentage = Math.round((items.length / workItems.length) * 100);
        arr.push(`${key}: ${percentage}%`);
      }
    });

    return arr;
  };
  const positions = getPositionGroupValues(wireAnalytics?.workItems ?? []);

  if (!wireAnalytics) {
    return null;
  }

  return (
    <Box>
      <Heading variant="h2" color="primary.100" textTransform="uppercase">
        {t("analyticsDetails.wireRef")}: {wireId}
      </Heading>

      <Heading
        variant="h4"
        color="primary.100"
        textTransform="uppercase"
        mt={10}>
        {t("analyticsDetails.details")}
      </Heading>
      <Stack direction={["column", "row"]} spacing={[2, 4, 6]} mt={5}>
        <Box width={["100%", "50%"]}>
          <Heading variant="h7" textTransform="uppercase">
            {t("analyticsDetails.totalWorkingTime")}
          </Heading>
          <Heading variant="h5">
            {getDatePresentationValue(wireAnalytics.totalWorkHours)}
          </Heading>
          <Heading variant="h7" textTransform="uppercase" mt={3}>
            {t("analyticsDetails.totalWorkingArea")}
          </Heading>
          <Heading variant="h5">
            {t("analyticsDetails.workingAreaValue", {
              value: wireAnalytics.totalWorkSize,
            })}
          </Heading>
          <Heading variant="h7" textTransform="uppercase" mt={3}>
            {t("analyticsDetails.position")}
          </Heading>
          <Heading variant="h5">
            {positions.length ? (
              positions.map((it, key) => <Text key={key}>{it}</Text>)
            ) : (
              <Text>-</Text>
            )}
          </Heading>
        </Box>
        <Box width={["100%", "50%"]}>
          <Heading variant="h7" textTransform="uppercase">
            {t("analyticsDetails.totalCuts")}
          </Heading>
          <Heading variant="h5">{wireAnalytics.totalCuts}</Heading>
          <Heading variant="h7" textTransform="uppercase" mt={3}>
            {t("analyticsDetails.averageCuttingSpeed")}
          </Heading>
          <Heading variant="h5">
            {t("analyticsDetails.averageSpeedValue", {
              value: wireAnalytics.totalAverageSpeed,
            })}
          </Heading>
        </Box>
      </Stack>

      <Heading
        variant="h4"
        color="primary.100"
        textTransform="uppercase"
        mt={5}
        mb={5}>
        {t("analyticsDetails.cutHistory")}
      </Heading>
      <Button
        mb={5}
        variant="secondary"
        size="sm"
        textTransform="uppercase"
        textDecoration={showHistory ? undefined : "line-through"}
        onClick={() => {
          if (showHistory) {
            setWireCutsFilters([
              { field: "startDate", values: [startDate] },
              { field: "endDate", values: [endDate] },
            ]);
          } else {
            setWireCutsFilters([
              { field: "startDate", values: [new Date(0).toISOString()] },
              { field: "endDate", values: [new Date().toISOString()] },
            ]);
          }

          setShowHistory(!showHistory);
        }}>
        {t("analyticsDetails.showHistory")}
      </Button>
      <ClassNames>
        {({ css, cx }) => (
          <DataTable
            value={wireAnalytics?.workItems}
            className={css`
              ${tableStyle}
            `}>
            <Column
              header={t("analyticsDetails.cutRef")}
              body={(workItem: MachineWorkItemSession) =>
                workItem.cutId ? (
                  <Link onClick={() => openCutDetails(workItem.cutId)}>
                    {workItem.sessionNumber}
                  </Link>
                ) : (
                  "-"
                )
              }
            />
            <Column
              header={t("analyticsDetails.machineInstanceId")}
              field="machineInstanceId"
            />
            <Column
              header={t("analyticsDetails.workSize")}
              body={(workItem: MachineWorkItemSession) =>
                t("analyticsDetails.workingAreaValue", {
                  value: workItem.workSize,
                })
              }
            />
            <Column
              header={t("analyticsDetails.workHours")}
              body={(workItem: MachineWorkItemSession) =>
                getDatePresentationValue(workItem.workHours)
              }
            />
            <Column
              header={t("analyticsDetails.averageSpeed")}
              body={(workItem: MachineWorkItemSession) =>
                t("analyticsDetails.averageSpeedValue", {
                  value: workItem.averageSpeed,
                })
              }
            />
            <Column
              header={t("analyticsDetails.position")}
              body={(workItem: MachineWorkItemSession) =>
                getPositionPresentationValue(
                  workItem.cutType,
                  workItem.cutPosition
                )
              }
            />
            <Column
              header={t("analyticsDetails.startDate")}
              body={(workItem: MachineWorkItemSession) =>
                moment(workItem.startDate).format("YYYY-MM-DD HH:mm")
              }
            />
            <Column
              header={t("analyticsDetails.endDate")}
              body={(workItem: MachineWorkItemSession) =>
                moment(workItem.endDate).format("YYYY-MM-DD HH:mm")
              }
            />
          </DataTable>
        )}
      </ClassNames>
    </Box>
  );
};

export default observer(AnalyticWireDetails);
