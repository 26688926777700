import {
  Stack,
  Box,
  Heading,
  Image,
  Grid,
  Button,
  useBreakpointValue,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useStoreContext } from "store/context";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { SensorGraph } from "./SensorGraph";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import { Panel } from "app/shared";
import { Measure } from "../models";
import { getMachineInstanceSensorData } from "../services";
import { ReactComponent as StopIcon } from "../static/Stop.svg";
import { ReactComponent as OpenIcon } from "../static/EyeOpen.svg";
import { ReactComponent as CloseIcon } from "../static/EyeClose.svg";
import { MachineSelector } from "./MachineSelector";
import { MachineProgress } from "./MachineProgress";
import { MachineStatusLabel } from "./MachineStatusLabel";
import moment from "moment";
import { getMeasurePresentationValue } from "../utils";
import { setIntervalImmediately } from "utils";
import { MachineSignalLabel } from "./MachineSignalLabel";

interface Params {
  id?: string;
}

const MachineInstanceDetails: React.FC<RouteComponentProps<Params>> = ({
  match,
}) => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const { machineStore } = useStoreContext();
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, xs: true, sm: false });
  const [selectedMachineId, setSelectedMachineId] = useState<
    string | undefined
  >(match.params.id);
  const [sensorData, setSensorData] = useState<Measure[]>([]);
  const [showMachineInfo, setShowMachineInfo] = useState(true);
  const [workingHours, setWorkingHours] = useState<string | undefined>();
  const [gsmType, setGsmType] = useState<string | undefined>();
  const [gsmSignal, setGsmSignal] = useState<string | undefined>();
  const [gsmOperator, setGsmOperator] = useState<string | undefined>();
  const [lastUpdate, setLastUpdate] = useState<string | undefined>();
  const [isHistorySync, setIsHistorySync] = useState(true);

  useEffect(() => {
    if (selectedMachineId) {
      let timer: number | undefined;

      // Update machine instance every minute
      timer = setIntervalImmediately(async () => {
        machineStore
          .getMachineInstance(selectedMachineId)
          .then((machineInstance) => {
            const inSync =
              new Date().getTime() -
                new Date(machineInstance.lastUpdateHistory).getTime() <
              5 * 60 * 1000; // more than 5 minutes
            setIsHistorySync(inSync);
          });
        if (machineStore.defaultMachineInstanceId !== selectedMachineId) {
          machineStore.setDefaultMachineInstanceId(selectedMachineId);
        }
        history.push(`/realtime/${selectedMachineId}`); // update URL with selected machine
      }, 60 * 1000);

      return () => {
        if (timer) {
          clearInterval(timer);
        }
      };
    }
  }, [history, machineStore, selectedMachineId]);

  useEffect(() => {
    setShowMachineInfo(isMobile ? false : true);
  }, [isMobile]);

  useEffect(() => {
    if (machineStore.machineInstance) {
      // Set last working hours
      const telemetryWorkingHours =
        machineStore.machineInstance.machineDefinition.telemetry.find(
          (it) => it.type === "WorkingHours"
        );

      if (telemetryWorkingHours) {
        const workingHoursValues = sensorData.filter(
          (it) => it.name === telemetryWorkingHours.name
        );
        const measure = workingHoursValues?.[workingHoursValues.length - 1];

        if (measure) {
          const value = getMeasurePresentationValue(
            measure.value,
            telemetryWorkingHours,
            machineStore.machineInstance.machineDefinition
          );

          if (value !== workingHours) {
            setWorkingHours(value);
          }
        }
      }

      // Set GSM type
      const telemetryGsmType =
        machineStore.machineInstance.machineDefinition.telemetry.find(
          (it) => it.type === "GSMType"
        );

      if (telemetryGsmType) {
        const values = sensorData.filter(
          (it) => it.name === telemetryGsmType.name
        );
        const measure = values?.[values.length - 1];

        if (measure) {
          const value = getMeasurePresentationValue(
            measure.value,
            telemetryGsmType,
            machineStore.machineInstance.machineDefinition
          );

          if (value !== gsmSignal) {
            setGsmType(value);
          }
        }
      }

      // Set GSM signal
      const telemetryGsmSignal =
        machineStore.machineInstance.machineDefinition.telemetry.find(
          (it) => it.type === "GSMSignal"
        );

      if (telemetryGsmSignal) {
        const values = sensorData.filter(
          (it) => it.name === telemetryGsmSignal.name
        );
        const measure = values?.[values.length - 1];

        if (measure) {
          const value = getMeasurePresentationValue(
            measure.value,
            telemetryGsmSignal,
            machineStore.machineInstance.machineDefinition
          );

          if (value !== gsmSignal) {
            setGsmSignal(value);
          }
        }
      }

      // Set GSM operator
      const telemetryGsmOperator =
        machineStore.machineInstance.machineDefinition.telemetry.find(
          (it) => it.type === "GSMOperator"
        );

      if (telemetryGsmOperator) {
        const values = sensorData.filter(
          (it) => it.name === telemetryGsmOperator.name
        );
        const measure = values?.[values.length - 1];

        if (measure) {
          const value = getMeasurePresentationValue(
            measure.value,
            telemetryGsmOperator,
            machineStore.machineInstance.machineDefinition
          );

          if (value !== gsmSignal) {
            setGsmOperator(value);
          }
        }
      }

      // Set last update
      const lastUpdateValue = sensorData?.[sensorData.length - 1]?.timestamp;
      if (
        lastUpdateValue > machineStore.machineInstance.lastUpdate &&
        (lastUpdate === undefined || lastUpdateValue > lastUpdate)
      ) {
        setLastUpdate(lastUpdateValue);
      }
    }
  }, [
    machineStore.machineInstance,
    sensorData,
    workingHours,
    gsmSignal,
    lastUpdate,
  ]);

  useEffect(() => {
    let isRunning: boolean = true;
    let timer: NodeJS.Timeout | undefined;
    let lastTimestamp = new Date();
    let counter = 0;
    let errors = 0;

    const defaultSensorIds: string[] =
      machineStore.machineInstance?.machineDefinition?.telemetry
        ?.filter((it) =>
          ["WorkingHours", "GSMType", "GSMSignal", "GSMOperator"].includes(
            it.type
          )
        )
        ?.map((it) => it.name) ?? [];

    if (machineStore.machineInstance?.configuration?.cuttingProgressFunction) {
      machineStore.machineInstance?.machineDefinition?.telemetry
        ?.filter((it) =>
          [
            "WorkingArea",
            "WorkingStatus",
            "AutoPilot",
            "NumberOfWorkSessions",
          ].includes(it.type)
        )
        ?.forEach((it) => defaultSensorIds.push(it.name));
    }

    const sensorIds = machineStore.machineInstance?.machineDefinition?.graphs
      ?.flatMap((graph) => graph.dataSources.map((it) => it.sensorId))
      ?.concat(defaultSensorIds)
      ?.filter((v, i, a) => a.indexOf(v) === i); // filter unique

    if (sensorIds?.length) {
      const getData = async () => {
        try {
          const now = new Date();
          const newMeasures: Measure[] = [];

          // Get sensor data
          const filters = [{ field: "sensors", values: sensorIds }];

          if (counter === 0) {
            filters.push({
              field: "startDate",
              values: [moment().utc().subtract(7, "days").toISOString()],
            });
            filters.push({ field: "rows", values: ["500"] });
          } else {
            // If tab is paused we should get all measures since the last request instead of 10 seconds ago
            const minStartDate = Math.min(
              moment(lastTimestamp)
                .utc()
                .subtract(10, "seconds")
                .toDate()
                .getTime(),
              moment().utc().subtract(10, "seconds").toDate().getTime()
            );

            filters.push({
              field: "startDate",
              values: [new Date(minStartDate).toISOString()],
            });
          }

          const machineInstanceId = machineStore.machineInstance!!.id;
          const currentData = await getMachineInstanceSensorData(
            machineInstanceId,
            {
              page: 0,
              size: 100,
              filters,
            }
          );
          currentData.forEach((a) =>
            a.measures.forEach((b) =>
              newMeasures.push({
                instanceId: a.instanceId,
                name: a.name,
                timestamp: b.timestamp,
                value: b.value,
              })
            )
          );

          if (newMeasures.length) {
            setSensorData((prevArray) => {
              const data =
                counter === 0
                  ? []
                  : prevArray.filter(
                      (it) => it.instanceId === machineInstanceId
                    );
              let newDataChanged = false;

              // Add new points
              newMeasures.forEach((newMeasure) => {
                const exists = data.find(
                  (it) =>
                    it.instanceId === newMeasure.instanceId &&
                    it.name === newMeasure.name &&
                    it.timestamp === newMeasure.timestamp &&
                    it.value === newMeasure.value
                );

                if (!exists) {
                  data.push(newMeasure);
                  newDataChanged = true;
                }
              });

              if (newDataChanged) {
                return [
                  ...data.sort((a, b) =>
                    a.timestamp.localeCompare(b.timestamp)
                  ),
                ];
              } else {
                return data;
              }
            });
          }

          lastTimestamp = now;
          counter += 1;
          errors = 0;
        } catch (err) {
          console.error(err);
          errors += 1;
        }

        if (isRunning && errors < 5) {
          timer = setTimeout(getData, 2 * 1000);
        }
      };

      getData();
    }

    return () => {
      if (timer) {
        isRunning = false;
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineStore.machineInstance?.id]); // must run once *only* when machine instance id changes

  return (
    <Stack direction={["column", "row"]} spacing={3}>
      <Panel width={["100%", "30%"]} p={0}>
        <Box position={["initial", "sticky"]} top={["initial", 0]} p={[2, 3]}>
          <MachineSelector
            selectedMachineInstanceId={selectedMachineId}
            onMachinesLoaded={(machineInstances) => {
              if (machineInstances.length && selectedMachineId === undefined) {
                setSelectedMachineId(
                  machineStore.defaultMachineInstanceId ??
                    machineInstances[0].id
                );
              }
            }}
            onMachineChange={(machineInstance) => {
              setSensorData([]);
              setWorkingHours(undefined);
              setGsmType(undefined);
              setGsmSignal(undefined);
              setGsmOperator(undefined);
              setLastUpdate(undefined);
              setIsHistorySync(true);
              setSelectedMachineId(machineInstance?.id);
            }}
          />
          {machineStore.machineInstance && (
            <>
              <Box textAlign="center" my={5}>
                <Image
                  src={machineStore.machineInstance.machineDefinition.image}
                  fallbackSrc="https://via.placeholder.com/150"
                  width="75%"
                  mx="auto"
                />
                {isMobile && (
                  <Button
                    leftIcon={showMachineInfo ? <CloseIcon /> : <OpenIcon />}
                    variant="ghost"
                    onClick={() => setShowMachineInfo(!showMachineInfo)}>
                    <Heading variant="h7" textTransform="uppercase">
                      {t(
                        showMachineInfo
                          ? "machineInstanceDetails.hideInfo"
                          : "machineInstanceDetails.showInfo"
                      )}
                    </Heading>
                  </Button>
                )}
              </Box>
              {showMachineInfo && (
                <Grid
                  templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
                  gridRowGap={3}
                  mt={5}>
                  <Box>
                    <Heading variant="h7" textTransform="uppercase">
                      {t("client")}
                    </Heading>
                    <Heading variant="h5">
                      {machineStore.machineInstance.client.name}
                    </Heading>
                  </Box>
                  <Box>
                    <Heading variant="h7" textTransform="uppercase">
                      {t("country")}
                    </Heading>
                    <Heading variant="h5">
                      {machineStore.machineInstance.site.country}
                    </Heading>
                  </Box>
                  <Box>
                    <Heading variant="h7" textTransform="uppercase">
                      {t("location")}
                    </Heading>
                    <Heading variant="h5">
                      {machineStore.machineInstance.site.location}
                    </Heading>
                  </Box>
                  <Box>
                    <Heading variant="h7" textTransform="uppercase">
                      {t("status")}
                    </Heading>
                    <Heading variant="h5">
                      <MachineStatusLabel
                        status={machineStore.machineInstance.status}
                      />
                    </Heading>
                  </Box>
                  <Box>
                    <Heading variant="h7" textTransform="uppercase">
                      {t("version")}
                    </Heading>
                    <Heading variant="h5">
                      {machineStore.machineInstance.version}
                    </Heading>
                  </Box>
                  <Box>
                    <Heading variant="h7" textTransform="uppercase">
                      {t("workingHours")}
                    </Heading>
                    <Heading variant="h5">{workingHours ?? "-"}</Heading>
                  </Box>
                  <Box>
                    <Heading variant="h7" textTransform="uppercase">
                      {t("signal")}
                    </Heading>
                    <Heading variant="h5">
                      <MachineSignalLabel
                        type={gsmType}
                        signal={gsmSignal}
                        operator={gsmOperator}
                      />
                    </Heading>
                  </Box>
                  <Box>
                    <Heading variant="h7" textTransform="uppercase">
                      {t("lastUpdate")}
                    </Heading>
                    <Heading variant="h5">
                      {moment(
                        lastUpdate ?? machineStore.machineInstance.lastUpdate
                      ).format("YYYY-MM-DD HH:mm:ss")}
                    </Heading>
                  </Box>
                </Grid>
              )}
              <Button
                isFullWidth
                mt={5}
                mb={2}
                textTransform="uppercase"
                rightIcon={<StopIcon />}
                disabled={machineStore.machineInstance.status === "Unavailable"}
                onClick={() => {
                  if (window.confirm("Are you sure?")) {
                    machineStore.stopMachineInstance(
                      machineStore.machineInstance!!.id
                    );
                  }
                }}>
                {t("machineInstanceDetails.stopButton")}
              </Button>
            </>
          )}
        </Box>
      </Panel>
      <Box width={["100%", "70%"]}>
        {machineStore.machineInstance && !isHistorySync && (
          <Alert status="info" borderRadius="default" mb={3}>
            <AlertIcon />
            {t("machineInstanceDetails.unreliableData", {
              date: moment(
                machineStore.machineInstance.lastUpdateHistory
              ).format("YYYY-MM-DD HH:mm:ss"),
            })}
          </Alert>
        )}
        <Grid templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]} gap={3}>
          {machineStore.machineInstance?.machineDefinition?.graphs?.map(
            (graph, key) => (
              <Panel
                key={key}
                gridColumn={graph.fullWidth ? ["1 / 3", "1 / 4"] : undefined}>
                <SensorGraph
                  graph={graph}
                  telemetry={
                    machineStore.machineInstance?.machineDefinition?.telemetry?.filter(
                      (telemetry) =>
                        graph.dataSources.find(
                          (it) => telemetry.name === it.sensorId
                        )
                    ) ?? []
                  }
                  definition={machineStore.machineInstance?.machineDefinition!!}
                  measures={sensorData.filter((sensor) =>
                    graph.dataSources.find((it) => sensor.name === it.sensorId)
                  )}
                  showLastValue={true}
                  lastTimestamp={lastUpdate ?? new Date().toISOString()}
                />
              </Panel>
            )
          )}
        </Grid>
        {machineStore.machineInstance?.configuration
          ?.cuttingProgressFunction && (
          <Panel mt={3}>
            <MachineProgress
              machineFunctionBody={
                machineStore.machineInstance?.configuration
                  ?.cuttingProgressFunction
              }
              measures={sensorData}
            />
          </Panel>
        )}
      </Box>
    </Stack>
  );
};

export default observer(MachineInstanceDetails);
