import { Select, SelectProps } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useStoreContext } from "store/context";
import { MachineInstanceListItem } from "../models";
import { getMachineInstances } from "../services";

interface Props extends SelectProps {
  machineInstances?: MachineInstanceListItem[];
  selectedMachineInstanceId?: string;
  onMachinesLoaded?: (machineInstances: MachineInstanceListItem[]) => void;
  onMachineChange?: (machineInstance?: MachineInstanceListItem) => void;
  placeholder?: string;
}

export const MachineSelector: React.FC<Props> = ({
  machineInstances,
  selectedMachineInstanceId,
  onMachinesLoaded,
  onMachineChange,
  placeholder,
  ...rest
}) => {
  const { machineStore } = useStoreContext();
  const [_machines, setMachines] = useState<MachineInstanceListItem[]>([]);
  const machines = machineInstances ?? _machines;

  useEffect(() => {
    if (machineInstances === undefined) {
      getMachineInstances({
        page: 0,
        size: 50,
        filters: [],
        sorts: [{ field: "id", value: "asc" }],
      }).then((res) => {
        setMachines(res.data);
        onMachinesLoaded?.(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineInstances]);

  return (
    <Select
      value={selectedMachineInstanceId}
      placeholder={placeholder}
      onChange={(event) => {
        const machineInstance = machines.find(
          (it) => it.id === event.target.value
        );
        if (
          machineInstance &&
          machineStore.defaultMachineInstanceId !== machineInstance.id
        ) {
          machineStore.setDefaultMachineInstanceId(machineInstance.id);
        }
        onMachineChange?.(machineInstance);
      }}
      {...rest}>
      {machines.map((it) => (
        <option key={it.id} value={it.id}>
          {it.id} - {it.client.name} - {it.site.location}
        </option>
      ))}
    </Select>
  );
};
