import UserStore from "app/users/stores/UserStore";
import React from "react";
import { MachineStore } from "../app/machines/stores";
import { StoreContext } from "./context";

interface Props {
  children: React.ReactNode;
}

export const StoreProvider: React.FC<Props> = ({ children }) => {
  const machineStore = new MachineStore();
  const userStore = new UserStore();

  return (
    <StoreContext.Provider value={{ machineStore, userStore }}>
      {children}
    </StoreContext.Provider>
  );
};
