import { Box, BoxProps, Heading } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import {
  BarChart as RechartsBarChart,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  CartesianGrid,
  YAxis,
  Legend,
  Bar,
  LabelList,
} from "recharts";

interface PointValue {
  [dataKey: string]: number;
}

export interface BarChartPoint extends PointValue {
  timestamp: number;
}

export interface BarChartConfig {
  dataKey: string;
  yAxisId: "left" | "right";
  fillColor: string;
  label: string;
  dataLabel?: string;
}

export interface AxisChartConfig {
  axisId: "left" | "right";
  label: string;
}

export interface Props extends BoxProps {
  data: BarChartPoint[];
  bars: BarChartConfig[];
  axis: AxisChartConfig[];
}

export const BarChart: React.FC<Props> = ({ data, bars, axis, ...rest }) => {
  if (data.length === 0) {
    return null;
  }

  const leftYAxis = axis.find((it) => it.axisId === "left");
  const rightYAxis = axis.find((it) => it.axisId === "right");

  return (
    <Box position="relative" {...rest}>
      {leftYAxis && (
        <Heading
          variant="h7Strong"
          textTransform="uppercase"
          position="absolute"
          left={0}
          top="20%"
          sx={{ writingMode: "vertical-rl" }}>
          {leftYAxis.label}
        </Heading>
      )}
      <ResponsiveContainer width="100%" height="100%">
        <RechartsBarChart
          data={data}
          barGap={0}
          barCategoryGap={20}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 10,
          }}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="timestamp"
            tickFormatter={(timestamp) => moment(timestamp).format("DD/MM")}
          />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip
            contentStyle={{ textAlign: "center" }}
            labelFormatter={(timestamp) =>
              moment(timestamp).format("DD MMM YYYY")
            }
          />
          {bars.map((bar, index) => (
            <Bar
              key={index}
              dataKey={bar.dataKey}
              fill={bar.fillColor}
              yAxisId={bar.yAxisId}
              name={bar.label}>
              {bar.dataLabel && (
                <LabelList
                  dataKey={bar.dataLabel}
                  position="top"
                  fontSize={12}
                />
              )}
            </Bar>
          ))}
          <Legend
            wrapperStyle={{ bottom: 0 }}
            align="center"
            color="red"
            iconType="square"
            iconSize={10}
          />
        </RechartsBarChart>
      </ResponsiveContainer>
      {rightYAxis && (
        <Heading
          variant="h7Strong"
          textTransform="uppercase"
          position="absolute"
          right={0}
          top="25%"
          sx={{ writingMode: "vertical-rl" }}>
          {rightYAxis.label}
        </Heading>
      )}
    </Box>
  );
};
