import React from "react";
import { MachineLogType } from "../models";
import { Stack, Text, StackProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import { ReactComponent as ErrorIcon } from "../static/Status-Error.svg";
import { ReactComponent as WarningIcon } from "../static/Status-Warning.svg";
import { ReactComponent as LogIcon } from "../static/Status-Log.svg";

interface Props extends StackProps {
  type: MachineLogType;
}

export const MachineLogTypeLabel: React.FC<Props> = ({ type, ...rest }) => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const options = [
    {
      type: "Error",
      translation: "machineLogTypeLabel.error",
      icon: <ErrorIcon />,
    },
    {
      type: "Warning",
      translation: "machineLogTypeLabel.warning",
      icon: <WarningIcon />,
    },
    {
      type: "Log",
      translation: "machineLogTypeLabel.log",
      icon: <LogIcon />,
    },
  ];
  const option = options.find((it) => it.type === type)!!;

  return (
    <Stack direction="row" alignItems="center" spacing={1} {...rest}>
      <Text>{t(option.translation)}</Text>
      {option.icon}
    </Stack>
  );
};
