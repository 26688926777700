import { Box, Heading, Link, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  MachineCutAnalyticsDTO,
  MachineWorkItem,
  Measure,
} from "app/machines/models";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { tableStyle } from "app/shared/tables/style";
import { Column } from "primereact/column";
import { ClassNames } from "@emotion/react";
import {
  getDatePresentationValue,
  getPositionPresentationValue,
} from "../utils";
import { useStoreContext } from "store/context";
import { MachineProgress } from "./MachineProgress";
import { observer } from "mobx-react";
import {
  getMachineCutDetails,
  getMachineInstanceSensorData,
} from "../services";
import { SensorGraph } from "./SensorGraph";

interface Props {
  cutId: string;
  openWireDetails: (
    wireId: string,
    cutStartDate: string,
    cutEndDate: string
  ) => void;
}

const AnalyticCutDetails: React.FC<Props> = ({ cutId, openWireDetails }) => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const { machineStore } = useStoreContext();
  const [analytics, setAnalytics] = useState<
    MachineCutAnalyticsDTO | undefined
  >();
  const [sensorData, setSensorData] = useState<Measure[]>([]);
  const [sessionStartDate, setSessionStartDate] = useState<
    string | undefined
  >();
  const [sessionEndDate, setSessionEndDate] = useState<string | undefined>();

  useEffect(() => {
    getMachineCutDetails(cutId).then((session) => {
      setAnalytics(session);

      const sessionStartDate = session.startDate;
      const sessionEndDate = moment(session.endDate)
        .utc()
        .subtract(30, "seconds")
        .toISOString();

      setSessionStartDate(sessionStartDate);
      setSessionEndDate(sessionEndDate);

      // Get machine data to build graph
      machineStore
        .getMachineInstance(session.machineInstanceId)
        .then(async (machineInstance) => {
          const sensorIds: string[] = [];

          machineInstance.machineDefinition?.telemetry
            ?.filter((it) =>
              [
                "WorkingArea",
                "WorkingStatus",
                "AutoPilot",
                "NumberOfWorkSessions",
                "TractionSpeed",
              ].includes(it.type)
            )
            ?.forEach((it) => sensorIds.push(it.name));

          if (sensorIds.length) {
            const data = await getMachineInstanceSensorData(
              machineInstance.id,
              {
                page: 0,
                size: 100,
                filters: [
                  { field: "sensors", values: sensorIds },
                  {
                    field: "startDate",
                    values: [sessionStartDate],
                  },
                  {
                    field: "endDate",
                    values: [sessionEndDate],
                  },
                  {
                    field: "rows",
                    values: ["1000"],
                  },
                ],
              }
            );
            const measures: Measure[] = [];
            data.forEach((a) =>
              a.measures.forEach((b) =>
                measures.push({
                  instanceId: a.instanceId,
                  name: a.name,
                  timestamp: b.timestamp,
                  value: b.value,
                })
              )
            );
            setSensorData(
              measures.sort((a, b) => a.timestamp.localeCompare(b.timestamp))
            );
          }
        });
    });
  }, [machineStore, cutId]);

  if (!analytics) {
    return null;
  }

  return (
    <Box>
      <Heading variant="h2" color="primary.100" textTransform="uppercase">
        {t("analyticsDetails.cutRef")}: {analytics.sessionNumber}
      </Heading>

      <Heading
        variant="h4"
        color="primary.100"
        textTransform="uppercase"
        mt={10}>
        {t("analyticsDetails.details")}
      </Heading>
      <Stack direction={["column", "row"]} spacing={[2, 4, 6]} mt={5}>
        <Box width={["100%", "50%"]}>
          <Heading variant="h7" textTransform="uppercase">
            {t("analyticsDetails.startDate")}
          </Heading>
          <Heading variant="h5">
            {moment(analytics.startDate).format("YYYY-MM-DD HH:mm")}
          </Heading>
          <Heading variant="h7" textTransform="uppercase" mt={3}>
            {t("analyticsDetails.totalWorkingTime")}
          </Heading>
          <Heading variant="h5">
            {getDatePresentationValue(analytics.workHours)}
          </Heading>
          <Heading variant="h7" textTransform="uppercase" mt={3}>
            {t("analyticsDetails.totalWorkingArea")}
          </Heading>
          <Heading variant="h5">
            {t("analyticsDetails.workingAreaValue", {
              value: analytics.workSize,
            })}
          </Heading>
          <Heading variant="h7" textTransform="uppercase" mt={3}>
            {t("analyticsDetails.position")}
          </Heading>
          <Heading variant="h5">
            {getPositionPresentationValue(
              analytics.cutType,
              analytics.cutPosition
            )}
          </Heading>
        </Box>
        <Box width={["100%", "50%"]}>
          <Heading variant="h7" textTransform="uppercase">
            {t("analyticsDetails.endDate")}
          </Heading>
          <Heading variant="h5">
            {moment(analytics.endDate).format("YYYY-MM-DD HH:mm")}
          </Heading>
          <Heading variant="h7" textTransform="uppercase" mt={3}>
            {t("analyticsDetails.machineInstanceId")}
          </Heading>
          <Heading variant="h5">{analytics.machineInstanceId}</Heading>
          <Heading variant="h7" textTransform="uppercase" mt={3}>
            {t("analyticsDetails.totalWires")}
          </Heading>
          <Heading variant="h5">{analytics.totalWires}</Heading>
          <Heading variant="h7" textTransform="uppercase" mt={3}>
            {t("analyticsDetails.averageCuttingSpeed")}
          </Heading>
          <Heading variant="h5">
            {t("analyticsDetails.averageSpeedValue", {
              value: analytics.averageSpeed,
            })}
          </Heading>
        </Box>
      </Stack>

      <Heading
        variant="h4"
        color="primary.100"
        textTransform="uppercase"
        mt={5}
        mb={5}>
        {t("analyticsDetails.cutHistory")}
      </Heading>
      <ClassNames>
        {({ css, cx }) => (
          <DataTable
            value={analytics.workItems}
            className={css`
              ${tableStyle}
            `}>
            <Column
              header={t("analyticsDetails.wireRef")}
              body={(workItem: MachineWorkItem) =>
                workItem.wireRef ? (
                  <Link
                    onClick={() =>
                      openWireDetails(
                        workItem.wireRef!!,
                        workItem.startDate,
                        workItem.endDate
                      )
                    }>
                    {workItem.wireRef}
                  </Link>
                ) : (
                  "-"
                )
              }
            />
            <Column
              header={t("analyticsDetails.workSize")}
              body={(workItem: MachineWorkItem) =>
                t("analyticsDetails.workingAreaValue", {
                  value: workItem.workSize,
                })
              }
            />
            <Column
              header={t("analyticsDetails.workHours")}
              body={(workItem: MachineWorkItem) =>
                getDatePresentationValue(workItem.workHours)
              }
            />
            <Column
              header={t("analyticsDetails.averageSpeed")}
              body={(workItem: MachineWorkItem) =>
                t("analyticsDetails.averageSpeedValue", {
                  value: workItem.averageSpeed,
                })
              }
            />
            <Column
              header={t("analyticsDetails.startDate")}
              body={(workItem: MachineWorkItem) =>
                moment(workItem.startDate).format("YYYY-MM-DD HH:mm")
              }
            />
            <Column
              header={t("analyticsDetails.endDate")}
              body={(workItem: MachineWorkItem) =>
                moment(workItem.endDate).format("YYYY-MM-DD HH:mm")
              }
            />
          </DataTable>
        )}
      </ClassNames>

      {sensorData.length > 0 && (
        <>
          <Heading
            variant="h4"
            color="primary.100"
            textTransform="uppercase"
            mt={5}
            mb={5}>
            {t("analyticsDetails.graphs")}
          </Heading>
          {machineStore.machineInstance?.configuration
            ?.cuttingProgressFunction && (
            <Box mb={5}>
              <MachineProgress
                machineFunctionBody={
                  machineStore.machineInstance?.configuration
                    ?.cuttingProgressFunction
                }
                measures={sensorData}
                endDate={sessionEndDate}
              />
            </Box>
          )}
          {machineStore.machineInstance?.machineDefinition?.telemetry
            ?.filter((it) => ["TractionSpeed"].includes(it.type))
            ?.map((telemetry, key) => (
              <SensorGraph
                key={key}
                graph={{
                  label: `${telemetry.label} (${telemetry.unit})`,
                  lastMinutes: -1,
                  fullWidth: true,
                  graphType: "Graph",
                  dataSources: [
                    {
                      sensorId: telemetry.name,
                      color: "#DC0000",
                    },
                  ],
                }}
                telemetry={[telemetry]}
                definition={machineStore.machineInstance?.machineDefinition!!}
                measures={sensorData.filter(
                  (sensor) => sensor.name === telemetry.name
                )}
                showLastValue={false}
                firstTimestamp={sessionStartDate}
                lastTimestamp={sessionEndDate!!}
                lineType="stepAfter"
              />
            ))}
        </>
      )}
    </Box>
  );
};

export default observer(AnalyticCutDetails);
