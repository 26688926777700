import { AppModule } from "AppModule";
import { registerResourceBundle, SUPPORTED_LANGUAGES } from "i18n";
import { MACHINES_NAMESPACE } from "./locales";
import { default as EN } from "./locales/en.json";

export { default as MachineInstancesList } from "./components/MachineInstancesList";
export { default as MachineInstanceDetails } from "./components/MachineInstanceDetails";
export { default as MachineLogsList } from "./components/MachineLogsList";
export { default as AnalyticDetails } from "./components/AnalyticDetails";

export class MachinesModule implements AppModule {
  setupTranslations() {
    registerResourceBundle(SUPPORTED_LANGUAGES.en, MACHINES_NAMESPACE, EN);
  }
}
