export const tableStyle = `
  text-align: center;
  word-break: break-all;

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  table thead th, td {
    padding: 0px;
  }

  table tbody th, td {
    padding: 20px;
  }

  table thead tr th {
    font-size: 11px;
    text-transform: uppercase;
    color: var(--chakra-colors-secondary-200);
  }

  table tbody tr td {
    font-size: 15px;
    color: var(--chakra-colors-secondary-100);
    font-weight: 600;
  }

  table tbody tr {
    border-bottom: 1px;
    border-style: solid;
    border-color: var(--chakra-colors-secondary-500);
  }

  table tbody tr:last-child {
    border-bottom: 0;
  }

  table tbody tr.column-datagrid {
    font-weight: bold;
  }

  table tbody tr.column-datagrid-odd {
    font-weight: normal;
  }

  div.p-datatable-wrapper th.p-sortable-column {
    cursor: pointer;
  }

  div.p-datatable-wrapper th.p-highlight span.p-column-title {
    padding-right: 5px;
  }
  
  div.p-datatable-wrapper tbody.p-datatable-tbody > tr > td > span.p-column-title {
    display: none;
  }

  @media screen and (max-width: 960px) {
    div.p-datatable-wrapper tbody.p-datatable-tbody > tr > td > span.p-column-title {
      display: block;
    } 
  }

  div.p-datatable-wrapper th.p-highlight span.pi-sort-amount-up-alt:after {
    content: '↑';
  }

  div.p-datatable-wrapper th.p-highlight span.pi-sort-amount-down:after {
    content: '↓';
  }

  div.p-paginator {
    display: flex;
    justify-content: space-between;
    padding: 12px;
  }

  div.p-paginator button.p-paginator-element:disabled {
    display: initial !important;
    visibility: hidden;
  }

  div.p-paginator button.p-paginator-element span.pi-angle-left:after {
    content: '←';
  }

  div.p-paginator button.p-paginator-element span.pi-angle-right:after {
    content: '→';
  }

  div.p-paginator button.p-paginator-element:disabled {
    display: none;
  }
`;
