import { makeAutoObservable, runInAction } from "mobx";
import { FilterField, SortField } from "network/models";
import {
  Notification,
  NotificationSetting,
  UpdateUserProfileCommand,
  UserProfile,
} from "../models";
import {
  countNotifications,
  getNotifications,
  getNotificationSettings,
  getUserProfile,
  readNotifications,
  updateNotificationSettings,
  updateUserProfile,
} from "../services";

export default class UserStore {
  notifications?: Notification[] = [];
  notificationSettings?: NotificationSetting[] = [];
  total = 0;
  unread = 0;
  notification?: Notification;
  userProfile?: UserProfile;
  state: "pending" | "done" | "error" = "done";
  error = "";

  constructor() {
    makeAutoObservable(this);
  }

  async wrapRequest<T>(body: () => Promise<T>): Promise<T> {
    try {
      this.state = "pending";
      this.error = "";
      const res = await body();
      runInAction(() => {
        this.state = "done";
      });
      return res;
    } catch (err) {
      runInAction(() => {
        this.state = "error";
        this.error = (err as any).message;
      });
      throw err;
    }
  }

  async getNotifications(
    page: number,
    size: number,
    filters: FilterField[],
    sorts?: SortField[]
  ): Promise<Notification[]> {
    return this.wrapRequest(async () => {
      const { meta, data } = await getNotifications({
        page,
        size,
        filters,
        sorts,
      });
      runInAction(() => {
        this.notifications = data;
        this.total = meta.totalElements;
      });
      return data;
    });
  }

  async getUnread(): Promise<number> {
    const count = await countNotifications();

    runInAction(() => {
      this.unread = count.unread;
    });

    return count.unread;
  }

  async readNotifications(): Promise<void> {
    await readNotifications();

    runInAction(() => {
      this.unread = 0;
    });
  }

  async getNotificationSettings(): Promise<NotificationSetting[]> {
    return this.wrapRequest(async () => {
      const data = await getNotificationSettings();
      runInAction(() => {
        this.notificationSettings = data;
      });
      return data;
    });
  }

  async updateNotificationSetting(
    updatedSetting: NotificationSetting
  ): Promise<NotificationSetting[]> {
    return this.wrapRequest(async () => {
      const data = await updateNotificationSettings([updatedSetting]);
      runInAction(() => {
        if (this.notificationSettings) {
          const otherSettings = this.notificationSettings.filter(
            (it) => it.groupKey !== updatedSetting.groupKey
          );
          this.notificationSettings = [...otherSettings, updatedSetting];
        }
      });
      return data;
    });
  }

  async getUserProfile(): Promise<UserProfile> {
    return this.wrapRequest(async () => {
      const data = await getUserProfile();
      runInAction(() => {
        this.userProfile = data;
      });
      return data;
    });
  }

  async updateUserProfile(cmd: UpdateUserProfileCommand): Promise<UserProfile> {
    return this.wrapRequest(async () => {
      const data = await updateUserProfile(cmd);
      return data;
    });
  }
}
