import { Global } from "@emotion/react";
const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Chevin Thin';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url('/fonts/Chevin-Thin.otf') format('opentype');
      }
      `}
  />
);

export default Fonts;
