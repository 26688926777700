import moment from "moment";
import { MachineDefinition, MachineTelemetry } from "../models";

export const getMeasureValue = (value: string): number | undefined => {
  const numericValue = Number(value);

  if (!isNaN(numericValue)) {
    return numericValue;
  } else if (value === "true") {
    return 1;
  } else if (value === "false") {
    return 0;
  }

  return undefined;
};

export const getMeasurePresentationValue = (
  value: string,
  telemetry: MachineTelemetry,
  definition: MachineDefinition
): string => {
  const unit =
    telemetry.unit.length && telemetry.unit.toLowerCase() !== "no unit"
      ? ` ${telemetry.unit}`
      : "";
  const numericValue = Number(value);

  if (telemetry.type === "Alarm") {
    const description = definition.alarms
      .find((it) => it.id === telemetry.name)
      ?.definition?.find((it) => it.name === value)?.text;

    return description ?? value;
  } else if (telemetry.type === "Notification") {
    const description = definition.notifications
      .find((it) => it.id === telemetry.name)
      ?.definition?.find((it) => it.name === value)?.text;

    return description ?? value;
  } else if (telemetry.unit.toLowerCase() === "h") {
    return getDatePresentationValue(numericValue);
  } else if (telemetry.type === "CutType") {
    return value === "1.0" || value === "1" || value === "true"
      ? "vertical"
      : "horizontal";
  } else if (telemetry.type === "CutPosition") {
    return value === "1.0" || value === "1" || value === "true"
      ? "upper"
      : "bottom";
  } else if (telemetry.dataType === "Boolean") {
    return value === "1.0" || value === "1" || value === "true" ? "on" : "off";
  } else if (!isNaN(numericValue)) {
    return `${Number(numericValue.toFixed(2)).toString()}${unit}`;
  }

  return `${value}${unit}`;
};

export const getDatePresentationValue = (hours: number): string => {
  const duration = moment.duration(hours * 60 * 60 * 1000);

  return `${Math.floor(hours)}H${
    duration.minutes() > 0 ? `:${duration.minutes()}MIN` : ""
  }`;
};

export const getPositionPresentationValue = (
  cutType?: boolean,
  cutPosition?: boolean
): string => {
  let value = "";

  if (cutType !== undefined && cutType !== null) {
    value += cutType === true ? "V" : "H";
  }
  if (cutPosition !== undefined && cutPosition !== null) {
    value += cutPosition === true ? "U" : "B";
  }

  return value.length ? value : "-";
};

export const getDurationPresentationValue = (diffMillis: number): string => {
  const timeRemainingDiff = moment.duration(diffMillis > 0 ? diffMillis : 0);
  return `${
    timeRemainingDiff.days() > 0 ? `${timeRemainingDiff.days()}D:` : ""
  }${timeRemainingDiff.hours()}H:${timeRemainingDiff.minutes()}MIN`;
};
