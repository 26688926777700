import { IntervalChart } from "app/shared";
import React from "react";
import { Measure } from "../models";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import moment from "moment";
import { IntervalChartPoint } from "app/shared/charts/IntervalChart";
import { getDurationPresentationValue } from "../utils";

interface Props {
  machineFunctionBody: string;
  measures: Measure[];
  startDate?: string;
  endDate?: string;
}

interface MachineFunctionRet {
  startDate?: string;
  endDate?: string;
  intervals?: IntervalChartPoint[];
}

export const MachineProgress: React.FC<Props> = ({
  machineFunctionBody,
  measures,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const calculeProgress = (
    body: string,
    measures: Measure[]
  ): MachineFunctionRet => {
    try {
      // eslint-disable-next-line no-new-func
      const machineFunction = new Function(
        "startDate",
        "endDate",
        "data",
        body
      );
      return machineFunction(startDate, endDate, measures);
    } catch (err) {
      console.error("Error calling machine function", err);
    }

    return {};
  };

  // Calculate progress
  const progress = calculeProgress(machineFunctionBody, measures);
  const timeRemaining = progress.endDate
    ? new Date(progress.endDate).getTime() - new Date().getTime()
    : undefined;

  return (
    <Box>
      <Heading variant="h6" textTransform="uppercase">
        {t("machineProgress.title")}
      </Heading>
      <Flex justifyContent="space-between" my={2}>
        <Box>
          {progress.startDate ? (
            <>
              <Heading
                variant="h3"
                color="primary.100"
                textTransform="uppercase"
                mb={1}>
                {moment(progress.startDate).format("HH:mm")}
              </Heading>
              <Heading variant="h7Strong" textTransform="uppercase">
                {moment(progress.startDate).format("YYYY-MM-DD")}
              </Heading>
            </>
          ) : (
            <Heading variant="h3" color="primary.100" textTransform="uppercase">
              -
            </Heading>
          )}
        </Box>
        <Box>
          {progress.endDate ? (
            <>
              <Heading
                variant="h3"
                color="primary.100"
                textTransform="uppercase"
                textAlign="right"
                mb={1}>
                {moment(progress.endDate).format("HH:mm")}
              </Heading>
              <Heading
                variant="h7Strong"
                textTransform="uppercase"
                textAlign="right">
                {moment(progress.endDate).format("YYYY-MM-DD")}
              </Heading>
            </>
          ) : (
            <Heading
              variant="h3"
              color="primary.100"
              textTransform="uppercase"
              textAlign="right">
              -
            </Heading>
          )}
        </Box>
      </Flex>
      {progress.startDate && progress.endDate && progress.intervals && (
        <IntervalChart
          beginDate={progress.startDate}
          endDate={progress.endDate}
          intervals={progress.intervals}
        />
      )}
      {timeRemaining !== undefined && timeRemaining > 0 && (
        <Flex justifyContent="flex-end" mt={2}>
          <Heading variant="h7" textTransform="uppercase">
            {t("machineProgress.timeRemaining", {
              time: getDurationPresentationValue(timeRemaining),
            })}
          </Heading>
        </Flex>
      )}
    </Box>
  );
};
