import axios from "network";
import { PageRequest, PageResponse } from "network/models";
import { stringifyPageRequest } from "network/utils";
import {
  Notification,
  NotificationsCount,
  NotificationSetting,
  UpdateUserProfileCommand,
  UserProfile,
} from "../models";

export const getNotifications = async (
  pageRequest?: PageRequest
): Promise<PageResponse<Notification>> => {
  return (
    await axios.get<PageResponse<Notification>>(
      `/notification${stringifyPageRequest(pageRequest)}`
    )
  ).data;
};

export const countNotifications = async (): Promise<NotificationsCount> => {
  return (await axios.get<NotificationsCount>(`/notificationCounter`)).data;
};

export const readNotifications = async (): Promise<void> => {
  return axios.post(`/notification/markAllRead`);
};

export const getNotificationSettings = async (): Promise<
  NotificationSetting[]
> => {
  return (await axios.get<NotificationSetting[]>(`/notificationSettings`)).data;
};

export const updateNotificationSettings = async (
  updatedSettings: NotificationSetting[]
): Promise<NotificationSetting[]> => {
  return (
    await axios.put<NotificationSetting[]>(`/notificationSettings`, {
      updatedSettings,
    })
  ).data;
};

export const updateUserProfile = async (
  cmd: UpdateUserProfileCommand
): Promise<UserProfile> => {
  return (await axios.put(`/user`, cmd)).data;
};

export const getUserProfile = async (): Promise<UserProfile> => {
  return (await axios.get<UserProfile>(`/user`)).data;
};
