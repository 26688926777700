import { Box, BoxProps } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";

interface Props extends BoxProps {
  callback: () => void;
  isOpen?: boolean;
}

export const OutsideClickWrapper: React.FC<Props> = ({
  isOpen,
  callback,
  children,
  ...rest
}) => {
  const wrapperRef = useRef(null);
  const handleClickOutside = (event: any) => {
    if (isOpen) {
      const currentNode: any = wrapperRef.current;
      if (currentNode && !currentNode.contains(event.target)) {
        callback();
      }
    }
  };
  useEffect(() => {
    isOpen
      ? document.addEventListener("mousedown", handleClickOutside)
      : document.removeEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <Box {...rest} ref={wrapperRef}>
      {children}
    </Box>
  );
};
