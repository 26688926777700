import React from "react";
import { Marker as MapsMarker } from "@react-google-maps/api";

interface Props {
  lat: number;
  lng: number;
  label?: string;
  color?: string;
  onClick?: () => void;
}

export const Marker: React.FC<Props> = ({
  lat,
  lng,
  label,
  color,
  onClick,
}) => (
  <MapsMarker
    position={{ lat, lng }}
    icon={{
      path: "M16,1 C24.836556,1 32,8.163444 32,17 C32,21.7572995 29.9237636,26.0296681 26.6279952,28.9604013 L16,39.69372 L5.371011,28.9595175 C2.07581903,26.0288092 0,21.7568214 0,17 C0,8.163444 7.163444,1 16,1 Z M16,9.95503426 C12.6862915,9.95503426 10,12.6413258 10,15.9550343 C10,19.2687428 12.6862915,21.9550343 16,21.9550343 C19.3137085,21.9550343 22,19.2687428 22,15.9550343 C22,12.6413258 19.3137085,9.95503426 16,9.95503426 Z",
      fillColor: color,
      fillOpacity: 1,
      scale: 1,
      strokeColor: color,
      strokeWeight: 1,
    }}
    label={label}
    onClick={onClick}
  />
);
