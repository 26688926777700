import React, { ReactNode, CSSProperties } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Coords } from "./models";
import configurations from "configurations";

interface Props {
  children?: ReactNode;
  center?: Coords;
  zoom?: number;
  minZoom?: number;
  maxZoom?: number;
  onClick?: (value: google.maps.MapMouseEvent) => void;
  mapContainerStyle?: CSSProperties;
}

export const Map: React.FC<Props> = ({
  children,
  center,
  zoom,
  minZoom,
  maxZoom,
  onClick,
  mapContainerStyle,
}) => {
  return (
    <LoadScript googleMapsApiKey={configurations.gmapsKey}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom}
        options={{ minZoom, maxZoom, fullscreenControl: false }}
        onClick={onClick}>
        {children}
      </GoogleMap>
    </LoadScript>
  );
};
