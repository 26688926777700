import { Checkbox } from "@chakra-ui/checkbox";
import { ClassNames } from "@emotion/react";
import { tableStyle } from "app/shared/tables/style";
import { observer } from "mobx-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStoreContext } from "store/context";
import { USERS_NAMESPACE } from "../locales";
import { NotificationChannel, NotificationSetting } from "../models";

const NotificationSettings = () => {
  const { t } = useTranslation(USERS_NAMESPACE);
  const { userStore } = useStoreContext();

  useEffect(() => {
    userStore.getNotificationSettings();
  }, [userStore]);

  const updateNotificationSetting = (
    setting: NotificationSetting,
    updatedChannel: NotificationChannel
  ) => {
    const otherChannels = setting.channels.filter(
      (it) => it.type !== updatedChannel.type
    );
    const updatedSetting: NotificationSetting = {
      ...setting,
      channels: [...otherChannels, updatedChannel],
    };
    userStore.updateNotificationSetting(updatedSetting);
  };

  return (
    <>
      {userStore.notificationSettings && (
        <ClassNames>
          {({ css, cx }) => (
            <DataTable
              loading={userStore.state === "pending"}
              lazy={true}
              value={userStore.notificationSettings!!.sort((a, b) =>
                a.groupKey.localeCompare(b.groupKey)
              )}
              className={css`
                ${tableStyle}
              `}>
              <Column
                header={t("profile.type")}
                field="groupKey"
                body={(setting: NotificationSetting) =>
                  t(`profile.${setting.groupKey}`)
                }
              />
              <Column
                header={t("profile.email")}
                body={(setting: NotificationSetting) => {
                  const email = setting.channels.find(
                    (it) => it.type === "Email"
                  );

                  return (
                    email && (
                      <Checkbox
                        colorScheme="red"
                        isChecked={email.enabled}
                        onChange={(event) =>
                          updateNotificationSetting(setting, {
                            type: "Email",
                            enabled: event.target.checked,
                          })
                        }
                      />
                    )
                  );
                }}
              />
              <Column
                header={t("profile.browser")}
                body={(setting: NotificationSetting) => {
                  const internalNotification = setting.channels.find(
                    (it) => it.type === "InternalNotification"
                  );

                  return (
                    internalNotification && (
                      <Checkbox
                        colorScheme="red"
                        isChecked={internalNotification.enabled}
                        onChange={(event) =>
                          updateNotificationSetting(setting, {
                            type: "InternalNotification",
                            enabled: event.target.checked,
                          })
                        }
                      />
                    )
                  );
                }}
              />
              <Column
                header={t("profile.sms")}
                body={(setting: NotificationSetting) => {
                  const sms = setting.channels.find((it) => it.type === "SMS");

                  return (
                    sms && (
                      <Checkbox
                        colorScheme="red"
                        isChecked={sms.enabled}
                        onChange={(event) =>
                          updateNotificationSetting(setting, {
                            type: "SMS",
                            enabled: event.target.checked,
                          })
                        }
                      />
                    )
                  );
                }}
              />
            </DataTable>
          )}
        </ClassNames>
      )}
    </>
  );
};

export default observer(NotificationSettings);
