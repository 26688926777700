import { observer } from "mobx-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { useStoreContext } from "store/context";
import { Notification } from "../models";
import { ClassNames } from "@emotion/react";
import { Panel } from "app/shared";
import { useTranslation } from "react-i18next";
import { USERS_NAMESPACE } from "../locales";
import moment from "moment";
import { tableStyle } from "app/shared/tables/style";
import { Link as ReactLink } from "react-router-dom";
import { Box, Flex, Link } from "@chakra-ui/react";

const NotificationsList: React.FC = () => {
  const { t } = useTranslation(USERS_NAMESPACE);
  const { userStore } = useStoreContext();
  const [page, setPage] = useState(0);
  const size = 50;

  useEffect(() => {
    userStore.getNotifications(page, size, [], []);
  }, [userStore, page, size]);

  return (
    <Panel>
      {userStore.state !== "error" && userStore.notifications && (
        <ClassNames>
          {({ css, cx }) => (
            <DataTable
              paginatorTemplate="PrevPageLink NextPageLink"
              paginator={true}
              loading={userStore.state === "pending"}
              lazy={true}
              rows={size}
              value={userStore.notifications}
              first={page * size}
              className={css`
                ${tableStyle}
              `}
              onPage={(e: any) => setPage(e.page)}
              totalRecords={userStore.total}>
              <Column
                header={t("description")}
                body={(notification: Notification) => {
                  if (notification.read) {
                    return notification.shortDescription;
                  } else {
                    return (
                      <Flex alignItems="center" justifyContent="center">
                        <Box
                          as="span"
                          bg="primary.100"
                          width="8px"
                          height="8px"
                          borderRadius="50%"
                          mr={2}>
                          &nbsp;
                        </Box>{" "}
                        {notification.shortDescription}
                      </Flex>
                    );
                  }
                }}
              />
              <Column
                header={t("machine")}
                body={(notification: Notification) => (
                  <Link
                    as={ReactLink}
                    to={`/realtime/${notification.entityId}`}>
                    {notification.entityId}
                  </Link>
                )}
              />
              <Column
                header={t("createdAt")}
                body={(notification: Notification) =>
                  moment(notification.createdAt).format("YYYY-MM-DD HH:mm:ss")
                }
              />
            </DataTable>
          )}
        </ClassNames>
      )}
    </Panel>
  );
};

export default observer(NotificationsList);
