import {
  Box,
  Grid,
  Heading,
  useDisclosure,
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  IconButton,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BarChart, DateRangeInput, Panel } from "app/shared";
import {
  AxisChartConfig,
  BarChartConfig,
  BarChartPoint,
  Props as BarChartProps,
} from "app/shared/charts/BarChart";
import {
  getMachineAnalytics,
  getMachineInstances,
  getMachineMonthlyReport,
  getMachineWeeklyReport,
} from "app/machines/services";
import {
  MachineAnalyticsDTO,
  MachineInstanceListItem,
  MachineCutSession,
  MachineWorkItemByWireRef,
} from "app/machines/models";
import { MachineSelector } from "app/machines/components/MachineSelector";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import { FilterField } from "network/models";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { tableStyle } from "app/shared/tables/style";
import { Column } from "primereact/column";
import { ClassNames } from "@emotion/react";
import { ReactComponent as ArrowIcon } from "../static/ArrowRightFull.svg";
import { ReactComponent as DownloadIcon } from "../static/Download.svg";
import { RouteComponentProps } from "react-router-dom";
import {
  getDatePresentationValue,
  getPositionPresentationValue,
} from "../utils";
import { useStoreContext } from "store/context";
import AnalyticCutDetails from "./AnalyticCutDetails";
import AnalyticWireDetails from "./AnalyticWireDetails";

interface Params {
  id?: string;
}

interface AnalyticsPopupState {
  cutId?: string;
  wireId?: string;
  startDate?: string;
  endDate?: string;
}

const AnalyticDetails: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const { machineStore } = useStoreContext();
  const primaryPopup = useDisclosure();
  const secondaryPopup = useDisclosure();
  const [analytics, setAnalytics] = useState<MachineAnalyticsDTO | undefined>();
  const [machines, setMachines] = useState<MachineInstanceListItem[]>([]);
  const [filters, setFilters] = useState<FilterField[]>([
    {
      field: "startDate",
      values: [moment().utc().add(-7, "days").startOf("day").toISOString()],
    },
    { field: "endDate", values: [moment().utc().endOf("day").toISOString()] },
  ]);
  const [primaryPopupState, setPrimaryPopupState] =
    useState<AnalyticsPopupState>({});
  const [secondaryPopupState, setSecondaryPopupState] =
    useState<AnalyticsPopupState>({});
  const [mode, setMode] = useState<"cut" | "wire">("cut");

  const buildMachineAnalyticsGraph = (
    analytics: MachineAnalyticsDTO
  ): BarChartProps => {
    const bars: BarChartConfig[] = [];
    const data: BarChartPoint[] = [];
    const axis: AxisChartConfig[] = [
      { axisId: "left", label: t("analyticsDetails.labelWorkHours") },
      { axisId: "right", label: t("analyticsDetails.labelWorkSize") },
    ];

    Object.keys(analytics.days).forEach((machineInstanceId, machineIndex) => {
      // Bars
      bars.push({
        dataKey: `${machineInstanceId}-workHours`,
        dataLabel: `${machineInstanceId}-averageSpeed`,
        yAxisId: "left",
        fillColor:
          machineIndex === 0
            ? "var(--chakra-colors-chart-primary-100)"
            : "var(--chakra-colors-chart-secondary-100)",
        label: `${machineInstanceId} - ${t("analyticsDetails.labelWorkHours")}`,
      });
      bars.push({
        dataKey: `${machineInstanceId}-workSize`,
        yAxisId: "right",
        fillColor:
          machineIndex === 0
            ? "var(--chakra-colors-chart-primary-200)"
            : "var(--chakra-colors-chart-secondary-200)",
        label: `${machineInstanceId} - ${t("analyticsDetails.labelWorkSize")}`,
      });

      // Data
      analytics.days[machineInstanceId].forEach((mDay) => {
        const timestamp = new Date(mDay.date).getTime();
        const existingPoint = data.find((it) => it.timestamp === timestamp);

        if (existingPoint) {
          existingPoint[`${machineInstanceId}-workHours`] = mDay.workHours;
          existingPoint[`${machineInstanceId}-workSize`] = mDay.workSize;

          if (mDay.averageSpeed > 0) {
            existingPoint[`${machineInstanceId}-averageSpeed`] =
              mDay.averageSpeed;
          }
        } else {
          const newPoint = {
            timestamp,
            [`${machineInstanceId}-workHours`]: mDay.workHours,
            [`${machineInstanceId}-workSize`]: mDay.workSize,
          };

          if (mDay.averageSpeed > 0) {
            newPoint[`${machineInstanceId}-averageSpeed`] = mDay.averageSpeed;
          }

          data.push(newPoint);
        }
      });
    });

    return { bars, data, axis };
  };

  const machineInstance1Id = filters.find(
    (it) => it.field === "machineInstanceId1"
  )?.values?.[0];
  const machineInstance2Id = filters.find(
    (it) => it.field === "machineInstanceId2"
  )?.values?.[0];
  const fromFilter = filters.find((it) => it.field === "startDate")
    ?.values?.[0];
  const toFilter = filters.find((it) => it.field === "endDate")?.values?.[0];
  const graphProps = analytics
    ? buildMachineAnalyticsGraph(analytics)
    : undefined;

  useEffect(() => {
    const getData = async () => {
      if (machineInstance1Id || machineInstance2Id) {
        const requestFilters = filters.filter(
          (it) =>
            it.field !== "machineInstanceId1" &&
            it.field !== "machineInstanceId2"
        );
        const machineInstanceIds =
          machineInstance1Id && machineInstance2Id
            ? [machineInstance1Id, machineInstance2Id]
            : [machineInstance1Id ?? machineInstance2Id ?? ""];
        requestFilters.push({ field: "instances", values: machineInstanceIds });

        const data = await getMachineAnalytics({
          page: 0,
          size: 50,
          filters: requestFilters,
        });
        setAnalytics(data);
      }
    };

    getData();
  }, [machineInstance1Id, machineInstance2Id, filters]);

  useEffect(() => {
    getMachineInstances({
      page: 0,
      size: 50,
      filters: [],
      sorts: [{ field: "id", value: "asc" }],
    }).then((res) => {
      setMachines(res.data);

      const newMachineInstanceId1 =
        match.params?.id ??
        machineStore.defaultMachineInstanceId ??
        res.data?.[0]?.id;

      if (newMachineInstanceId1) {
        setFilters([
          ...filters,
          { field: "machineInstanceId1", values: [newMachineInstanceId1] },
        ]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box position="relative">
      <Panel>
        <Grid
          gridTemplateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
          gridColumnGap={5}
          mb={10}>
          <MachineSelector
            machineInstances={machines.filter(
              (it) => it.id !== machineInstance2Id
            )}
            selectedMachineInstanceId={machineInstance1Id}
            placeholder={t("analyticsDetails.selectMachine")}
            onMachineChange={(machineInstance) => {
              const newFilters = filters.filter(
                (it) => it.field !== "machineInstanceId1"
              );

              if (machineInstance) {
                newFilters.push({
                  field: "machineInstanceId1",
                  values: [machineInstance.id],
                });
              }

              setFilters([...newFilters]);
            }}
          />
          <MachineSelector
            machineInstances={machines.filter(
              (it) => it.id !== machineInstance1Id
            )}
            selectedMachineInstanceId={machineInstance2Id}
            placeholder={t("analyticsDetails.selectMachine")}
            my={[3, 0]}
            onMachineChange={(machineInstance) => {
              const newFilters = filters.filter(
                (it) => it.field !== "machineInstanceId2"
              );

              if (machineInstance) {
                newFilters.push({
                  field: "machineInstanceId2",
                  values: [machineInstance.id],
                });
              }

              setFilters([...newFilters]);
            }}
          />
          <DateRangeInput
            defaultFrom={fromFilter ? new Date(fromFilter) : undefined}
            defaultTo={toFilter ? new Date(toFilter) : undefined}
            onChange={(from, to) => {
              const newFilters = filters.filter(
                (it) => it.field !== "startDate" && it.field !== "endDate"
              );

              if (from) {
                newFilters.push({
                  field: "startDate",
                  values: [from.toISOString()],
                });
              }
              if (to) {
                newFilters.push({
                  field: "endDate",
                  values: [to.toISOString()],
                });
              }

              setFilters([...newFilters]);
            }}
          />
        </Grid>
        {analytics && (
          <>
            <Grid
              templateColumns={["repeat(1, 1fr)", "repeat(5, 1fr)"]}
              gap={6}
              my={10}>
              <Box
                p={4}
                border={1}
                borderStyle="solid"
                borderColor="secondary.600"
                borderRadius="default"
                textAlign="center">
                <Heading variant="h6" textTransform="uppercase">
                  {t("analyticsDetails.totalWorkingTime")}
                </Heading>
                <Heading variant="h3" textTransform="uppercase">
                  {getDatePresentationValue(analytics.totalWorkHours)}
                </Heading>
              </Box>
              <Box
                p={4}
                border={1}
                borderStyle="solid"
                borderColor="secondary.600"
                borderRadius="default"
                textAlign="center">
                <Heading variant="h6" textTransform="uppercase">
                  {t("analyticsDetails.totalWorkingArea")}
                </Heading>
                <Heading variant="h3" textTransform="uppercase">
                  {t("analyticsDetails.workingAreaValue", {
                    value: analytics.totalWorkSize,
                  })}
                </Heading>
              </Box>
              <Box
                p={4}
                border={1}
                borderStyle="solid"
                borderColor="secondary.600"
                borderRadius="default"
                textAlign="center">
                <Heading variant="h6" textTransform="uppercase">
                  {t("analyticsDetails.averageCuttingSpeed")}
                </Heading>
                <Heading variant="h3" textTransform="uppercase">
                  {t("analyticsDetails.averageSpeedValue", {
                    value: analytics.totalAverageSpeed,
                  })}
                </Heading>
              </Box>
              <Box
                p={4}
                border={1}
                borderStyle="solid"
                borderColor={mode === "cut" ? "secondary.100" : "secondary.600"}
                borderRadius="default"
                cursor="pointer"
                textAlign="center"
                onClick={() => setMode("cut")}>
                <Heading variant="h6" textTransform="uppercase">
                  {t("analyticsDetails.totalCuts")}
                </Heading>
                <Heading variant="h3" textTransform="uppercase">
                  {analytics.totalCuts}
                </Heading>
              </Box>
              <Box
                p={4}
                border={1}
                borderStyle="solid"
                borderColor={
                  mode === "wire" ? "secondary.100" : "secondary.600"
                }
                borderRadius="default"
                cursor="pointer"
                textAlign="center"
                onClick={() => setMode("wire")}>
                <Heading variant="h6" textTransform="uppercase">
                  {t("analyticsDetails.totalWires")}
                </Heading>
                <Heading variant="h3" textTransform="uppercase">
                  {analytics.totalWires}
                </Heading>
              </Box>
            </Grid>
            {graphProps && <BarChart {...graphProps} height="300px" mb={10} />}
            <ClassNames>
              {({ css, cx }) => (
                <DataTable
                  value={
                    mode === "cut" ? analytics.sessions : analytics.workItems
                  }
                  className={css`
                    ${tableStyle}
                  `}>
                  {mode === "cut" && (
                    <Column
                      header={t("analyticsDetails.cutRef")}
                      field="sessionNumber"
                    />
                  )}
                  {mode === "cut" && (
                    <Column
                      header={t("analyticsDetails.machineInstanceId")}
                      field="machineInstanceId"
                    />
                  )}
                  {mode === "cut" && (
                    <Column
                      header={t("analyticsDetails.totalWires")}
                      field="totalWires"
                    />
                  )}
                  {mode === "wire" && (
                    <Column
                      header={t("analyticsDetails.wireRef")}
                      body={(item: MachineWorkItemByWireRef) =>
                        item.wireRef ?? "-"
                      }
                    />
                  )}
                  {mode === "wire" && (
                    <Column
                      header={t("analyticsDetails.totalCuts")}
                      body={(item: MachineWorkItemByWireRef) =>
                        item.wireRef ? item.totalCuts : "-"
                      }
                    />
                  )}
                  <Column
                    header={t("analyticsDetails.workSize")}
                    body={(
                      item: MachineCutSession | MachineWorkItemByWireRef
                    ) =>
                      t("analyticsDetails.workingAreaValue", {
                        value: item.workSize,
                      })
                    }
                  />
                  <Column
                    header={t("analyticsDetails.workHours")}
                    body={(
                      item: MachineCutSession | MachineWorkItemByWireRef
                    ) => getDatePresentationValue(item.workHours)}
                  />
                  <Column
                    header={t("analyticsDetails.averageSpeed")}
                    body={(
                      item: MachineCutSession | MachineWorkItemByWireRef
                    ) =>
                      t("analyticsDetails.averageSpeedValue", {
                        value: item.averageSpeed,
                      })
                    }
                  />
                  {mode === "cut" && (
                    <Column
                      header={t("analyticsDetails.position")}
                      body={(item: MachineCutSession) =>
                        getPositionPresentationValue(
                          item.cutType,
                          item.cutPosition
                        )
                      }
                    />
                  )}
                  <Column
                    header={t("analyticsDetails.startDate")}
                    body={(
                      item: MachineCutSession | MachineWorkItemByWireRef
                    ) => moment(item.startDate).format("YYYY-MM-DD HH:mm")}
                  />
                  {mode === "cut" && (
                    <Column
                      header={t("analyticsDetails.endDate")}
                      body={(session: MachineCutSession) =>
                        moment(session.endDate).format("YYYY-MM-DD HH:mm")
                      }
                    />
                  )}
                  {mode === "cut" && (
                    <Column
                      body={(session: MachineCutSession) => (
                        <IconButton
                          variant="secondary"
                          aria-label="Details"
                          icon={<ArrowIcon />}
                          onClick={() => {
                            setPrimaryPopupState({ cutId: session.id });
                            primaryPopup.onOpen();
                          }}
                          size="sm"
                        />
                      )}
                    />
                  )}
                  {mode === "wire" && (
                    <Column
                      body={(workItem: MachineWorkItemByWireRef) =>
                        workItem.wireRef && (
                          <IconButton
                            variant="secondary"
                            aria-label="Details"
                            icon={<ArrowIcon />}
                            onClick={() => {
                              setPrimaryPopupState({
                                wireId: workItem.wireRef,
                                startDate: workItem.startDate,
                                endDate: workItem.endDate,
                              });
                              primaryPopup.onOpen();
                            }}
                            size="sm"
                          />
                        )
                      }
                    />
                  )}
                </DataTable>
              )}
            </ClassNames>
          </>
        )}
      </Panel>
      <Box position="absolute" bottom={[-2, -4]} right={3}>
        {machineInstance1Id !== undefined && machineInstance2Id === undefined && (
          <>
            <IconButton
              variant="primary"
              borderRadius="50%"
              aria-label="Weekly Report"
              icon={<DownloadIcon title="Weekly Report" />}
              disabled={machineInstance2Id !== undefined}
              onClick={() => {
                if (machineInstance1Id && fromFilter && toFilter) {
                  getMachineWeeklyReport(machineInstance1Id, {
                    page: 0,
                    size: 100,
                    filters: [
                      { field: "startDate", values: [fromFilter] },
                      { field: "endDate", values: [toFilter] },
                    ],
                  }).then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.data], { type: "application/pdf" })
                    );
                    window.open(url);
                  });
                }
              }}
            />
            <IconButton
              variant="primary"
              borderRadius="50%"
              ml={3}
              aria-label="Monthly Report"
              disabled={machineInstance2Id !== undefined}
              icon={<DownloadIcon title="Monthly Report" />}
              onClick={() => {
                if (fromFilter && toFilter) {
                  getMachineMonthlyReport({
                    page: 0,
                    size: 100,
                    filters: [
                      { field: "startDate", values: [fromFilter] },
                      { field: "endDate", values: [toFilter] },
                    ],
                  }).then((res) => {
                    const url = window.URL.createObjectURL(
                      new Blob([res.data], { type: "application/pdf" })
                    );
                    window.open(url);
                  });
                }
              }}
            />
          </>
        )}
      </Box>
      <Modal
        isOpen={primaryPopup.isOpen}
        onClose={primaryPopup.onClose}
        size="full">
        <ModalOverlay />
        <ModalContent bg="transparent">
          <ModalCloseButton />
          <ModalBody m={0} p={0}>
            <Flex
              flexDirection={["column", "row"]}
              minHeight={["initial", "100vh"]}>
              <Box width={["100%", "50%"]} />
              <Box
                width={["100%", "50%"]}
                bg="white"
                p={5}
                borderLeftRadius={["initial", "16px"]}
                boxShadow="0 0px 5px 0px var(--chakra-colors-secondary-100)">
                {primaryPopupState.cutId && (
                  <AnalyticCutDetails
                    cutId={primaryPopupState.cutId}
                    openWireDetails={(wireId, startDate, endDate) => {
                      setSecondaryPopupState({ wireId, startDate, endDate });
                      secondaryPopup.onOpen();
                    }}
                  />
                )}
                {primaryPopupState.wireId &&
                  primaryPopupState.startDate &&
                  primaryPopupState.endDate && (
                    <AnalyticWireDetails
                      wireId={primaryPopupState.wireId}
                      startDate={primaryPopupState.startDate}
                      endDate={primaryPopupState.endDate}
                      openCutDetails={(cutId) => {
                        setSecondaryPopupState({ cutId });
                        secondaryPopup.onOpen();
                      }}
                    />
                  )}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={secondaryPopup.isOpen}
        onClose={secondaryPopup.onClose}
        size="full">
        <ModalOverlay />
        <ModalContent bg="transparent">
          <ModalCloseButton />
          <ModalBody m={0} p={0}>
            <Flex
              flexDirection={["column", "row"]}
              minHeight={["initial", "100vh"]}>
              <Box width={["100%", "50%"]} />
              <Box
                width={["100%", "50%"]}
                bg="white"
                p={5}
                borderLeftRadius={["initial", "16px"]}
                boxShadow="0 0px 5px 0px var(--chakra-colors-secondary-100)">
                {secondaryPopupState.cutId && (
                  <AnalyticCutDetails
                    cutId={secondaryPopupState.cutId}
                    openWireDetails={(wireId, startDate, endDate) => {
                      setSecondaryPopupState({ wireId, startDate, endDate });
                      secondaryPopup.onOpen();
                    }}
                  />
                )}
                {secondaryPopupState.wireId &&
                  secondaryPopupState.startDate &&
                  secondaryPopupState.endDate && (
                    <AnalyticWireDetails
                      wireId={secondaryPopupState.wireId}
                      startDate={secondaryPopupState.startDate}
                      endDate={secondaryPopupState.endDate}
                      openCutDetails={(cutId) => {
                        setSecondaryPopupState({ cutId });
                        secondaryPopup.onOpen();
                      }}
                    />
                  )}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AnalyticDetails;
