import { Box, BoxProps } from "@chakra-ui/react";

interface Props extends BoxProps {
  children: React.ReactNode;
}

export const Panel: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <Box bg="white" borderRadius="default" p={[2, 3]} {...rest}>
      {children}
    </Box>
  );
};
