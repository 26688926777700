import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { AUTH_NAMESPACE } from "../locales";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import {
  AuthAttributes,
  PasswordForm,
  PasswordFormValidationSchema,
} from "auth/models";

export const AuthSettings: React.FC = () => {
  const { t } = useTranslation(AUTH_NAMESPACE);
  const [attributes, setAttributes] = useState<AuthAttributes | undefined>();
  const toast = useToast();

  useEffect(() => {
    const call = async () => {
      const { attributes } = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      setAttributes(attributes);
    };
    call();
  }, []);

  return (
    <Formik<PasswordForm>
      initialValues={{
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      validationSchema={PasswordFormValidationSchema}
      onSubmit={async (values, actions) => {
        try {
          const user = await Auth.currentAuthenticatedUser();
          await Auth.changePassword(
            user,
            values.currentPassword,
            values.newPassword
          );
          actions.resetForm();
          toast({
            title: t("account.submitToastTitle"),
            description: t("account.submitToastDescription"),
            status: "success",
            isClosable: true,
          });
        } catch (err: any) {
          actions.setFieldError("currentPassword", err.message);
        }

        actions.setSubmitting(false);
      }}>
      {(props) => {
        return (
          <Form>
            <FormControl isReadOnly>
              <FormLabel textTransform="uppercase">
                {t("account.email")}
              </FormLabel>
              <Input type="email" value={attributes?.email} />
            </FormControl>
            <Field name="currentPassword">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={
                    form.errors[field.name] !== undefined &&
                    form.touched[field.name] !== undefined
                  }
                  mt={4}>
                  <FormLabel textTransform="uppercase">
                    {t("account.currentPassword")}
                  </FormLabel>
                  <Input {...field} type="password" />
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="newPassword">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={
                    form.errors[field.name] !== undefined &&
                    form.touched[field.name] !== undefined
                  }
                  mt={4}>
                  <FormLabel textTransform="uppercase">
                    {t("account.newPassword")}
                  </FormLabel>
                  <Input {...field} type="password" />
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="confirmNewPassword">
              {({ field, form }: FieldProps) => (
                <FormControl
                  isRequired
                  isInvalid={
                    form.errors[field.name] !== undefined &&
                    form.touched[field.name] !== undefined
                  }
                  mt={4}>
                  <FormLabel textTransform="uppercase">
                    {t("account.confirmNewPassword")}
                  </FormLabel>
                  <Input {...field} type="password" />
                  <FormErrorMessage>{form.errors[field.name]}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              mt={4}
              textTransform="uppercase"
              isLoading={props.isSubmitting}
              type="submit">
              {t("account.submit")}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
