import App from "App";
import { MachinesModule } from "app/machines";
import { UsersModule } from "app/users";
import { AppModule } from "AppModule";
import { AuthModule } from "auth";
import Amplify from "aws-amplify";
import environment from "configurations";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { StoreProvider } from "./store";

// Setup cognito
Amplify.configure({ Auth: environment.cognito });

// Load application modules
const modules: AppModule[] = [
  new AuthModule(),
  new MachinesModule(),
  new UsersModule(),
];
modules.forEach((module) => module.setupTranslations());

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <App />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//TODO: activate web vitals reporting: https://create-react-app.dev/docs/measuring-performance/
