import { observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStoreContext } from "store/context";
import { USERS_NAMESPACE } from "../locales";
import { ProfileForm, ProfileFormValidationSchema } from "../models";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { TimezoneSelect } from "app/shared";

const NotificationSettings = () => {
  const { t } = useTranslation(USERS_NAMESPACE);
  const { userStore } = useStoreContext();
  const toast = useToast();

  useEffect(() => {
    userStore.getUserProfile();
  }, [userStore]);

  return (
    <>
      {userStore.userProfile && (
        <Formik<ProfileForm>
          initialValues={{
            phoneNumber: userStore.userProfile.phoneNumber ?? "",
            timezoneId: userStore.userProfile.timezoneId,
          }}
          validationSchema={ProfileFormValidationSchema}
          onSubmit={async (values, actions) => {
            try {
              await userStore.updateUserProfile({
                phoneNumber: values.phoneNumber,
                timezoneId: values.timezoneId,
              });
              toast({
                title: t("profile.submitToastTitle"),
                description: t("profile.submitToastDescription"),
                status: "success",
                isClosable: true,
              });
            } catch (err: any) {
              actions.setFieldError("phoneNumber", err.message);
            }

            actions.setSubmitting(false);
          }}>
          {(props) => {
            return (
              <Form>
                <Field name="phoneNumber">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors[field.name] !== undefined &&
                        form.touched[field.name] !== undefined
                      }>
                      <FormLabel textTransform="uppercase">
                        {t("profile.phoneNumber")}
                      </FormLabel>
                      <Input {...field} type="tel" />
                      <FormErrorMessage>
                        {form.errors[field.name]}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="timezoneId">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      mt={4}
                      isRequired
                      isInvalid={
                        form.errors[field.name] !== undefined &&
                        form.touched[field.name] !== undefined
                      }>
                      <FormLabel textTransform="uppercase">
                        {t("profile.timezoneId")}
                      </FormLabel>
                      <TimezoneSelect {...field} />
                      <FormErrorMessage>
                        {form.errors[field.name]}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt={4}
                  textTransform="uppercase"
                  isLoading={props.isSubmitting}
                  type="submit">
                  {t("profile.submit")}
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default observer(NotificationSettings);
