import React from "react";
import { MachineInstanceStatus } from "../models";
import { Stack, Text, StackProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import { ReactComponent as OnlineIcon } from "../static/Status-Idle.svg";
import { ReactComponent as CuttingIcon } from "../static/Status-Cutting.svg";
import { ReactComponent as UnavailableIcon } from "../static/Status-Unavailable.svg";
import { ReactComponent as OfflineIcon } from "../static/Status-Off.svg";

interface Props extends StackProps {
  status: MachineInstanceStatus;
}

export const MachineStatusLabel: React.FC<Props> = ({ status, ...rest }) => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const options = [
    {
      status: "Online",
      translation: "machineStatusLabel.online",
      icon: <OnlineIcon />,
    },
    {
      status: "Cutting",
      translation: "machineStatusLabel.cutting",
      icon: <CuttingIcon />,
    },
    {
      status: "Unavailable",
      translation: "machineStatusLabel.unavailable",
      icon: <UnavailableIcon />,
    },
    {
      status: "Offline",
      translation: "machineStatusLabel.offline",
      icon: <OfflineIcon />,
    },
  ];
  const option = options.find((it) => it.status === status)!!;

  return (
    <Stack direction="row" alignItems="center" spacing={1} {...rest}>
      <Text>{t(option.translation)}</Text>
      {option.icon}
    </Stack>
  );
};
