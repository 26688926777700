const Input = {
  variants: {
    primary: {
      field: {
        bg: "secondary.600",
        borderRadius: "default",
      },
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

export default Input;
