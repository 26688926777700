module.exports = {
  production: true,
  api: "https://api.app.osm.com.pt/v1/webapp",
  cognito: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_QIRMaLRbk",
    userPoolWebClientId: "7gsfm326balq84256iqrgigedu",
  },
  gmapsKey: "AIzaSyBe_bG3jOM9qTiotov4C7yQRA6uPqhZ9DI",
};
