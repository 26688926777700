import React from "react";
import { Auth } from "aws-amplify";
import { AUTH_NAMESPACE } from "../locales";
import { useTranslation } from "react-i18next";
import { ReactComponent as LogoutIcon } from "../static/Exit.svg";
import { IconButton } from "@chakra-ui/react";
import { LOCAL_STORAGE_DEFAULT_MACHINE_ID } from "app/machines/stores/MachineStore";

export const LogoutButton: React.FC = () => {
  const { t } = useTranslation(AUTH_NAMESPACE);
  const onClick = async () => {
    await Auth.signOut();
    localStorage.removeItem(LOCAL_STORAGE_DEFAULT_MACHINE_ID);
    window.location.replace("/");
  };

  return (
    <IconButton
      variant="ghost"
      aria-label={t("logout")}
      icon={<LogoutIcon />}
      onClick={async () => {
        await onClick();
      }}
    />
  );
};
