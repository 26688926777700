module.exports = {
  production: false,
  api: "https://api.osm.waterdog.mobi/v1/webapp",
  cognito: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_RJd8eIEww",
    userPoolWebClientId: "38u90otnurdqvr7u5ahk49roen",
  },
  gmapsKey: "AIzaSyBe_bG3jOM9qTiotov4C7yQRA6uPqhZ9DI",
};
