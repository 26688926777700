import { ChakraProvider, Container, Grid } from "@chakra-ui/react";
import { Navbar } from "app/shared";
// See: https://chakra-ui.com/docs/migration#css-reset to remove focus on non kb interactions
import "focus-visible/dist/focus-visible";
import { useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import theme from "theme";
import Fonts from "theme/fonts";
import {
  MachineInstanceDetails,
  MachineInstancesList,
  MachineLogsList,
  AnalyticDetails,
} from "app/machines";
import { NotificationsList, Profile } from "app/users";
import { SignIn } from "auth/components/SignIn";

/*
 * Entry point of the application.
 */
const App = () => {
  const [authState, setAuthState] = useState<string>();

  return (
    <ChakraProvider theme={theme}>
      <>
        <Fonts />
        {authState === "signedin" ? (
          <BrowserRouter>
            <Grid
              gridTemplateRows={["52px 1fr", "72px 1fr"]}
              gridRowGap={[2, 3]}
              height="100vh">
              <Navbar />
              <Container
                maxW="container.full"
                px={[2, 3]}
                pb={["75px", 3]}
                overflowX="hidden">
                <Switch>
                  <Route exact path="/" component={MachineInstancesList} />
                  <Route
                    exact
                    path="/machines"
                    component={MachineInstancesList}
                  />
                  <Route
                    path="/realtime/:id?"
                    component={MachineInstanceDetails}
                  />
                  <Route path="/analytics/:id?" component={AnalyticDetails} />
                  <Route exact path="/logs" component={MachineLogsList} />
                  <Route
                    exact
                    path="/notifications"
                    component={NotificationsList}
                  />
                  <Route exact path="/profile" component={Profile} />
                  <Redirect from="*" to="/404" />
                </Switch>
              </Container>
            </Grid>
          </BrowserRouter>
        ) : (
          <SignIn onChange={(state) => setAuthState(state)} />
        )}
      </>
    </ChakraProvider>
  );
};

export default App;
