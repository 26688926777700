import { Flex, LinkProps, Link, Heading } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { ReactNode } from "react";

interface Props extends LinkProps {
  title: string;
  icon: ReactNode;
  to: string;
  active?: boolean;
}

export const MenuItem: React.FC<Props> = ({
  title,
  icon,
  to,
  active,
  ...rest
}) => (
  <Link
    as={ReactLink}
    to={to}
    textTransform="uppercase"
    p={[1, 2]}
    borderBottom={3}
    borderColor={active ? "var(--chakra-colors-primary-100)" : "#fff"}
    borderStyle="solid"
    sx={{
      "&:hover": {
        textDecoration: "none",
      },
    }}
    {...rest}>
    <Flex
      flexDirection="column"
      alignItems="center"
      sx={{
        "& > svg g": {
          stroke: active ? "var(--chakra-colors-primary-100)" : undefined,
        },
      }}>
      {icon}
      <Heading variant={active ? "h7Strong" : "h7"} mt={1}>
        {title}
      </Heading>
    </Flex>
  </Link>
);
