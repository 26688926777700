import { Flex, Spacer, IconButton } from "@chakra-ui/react";
import { MACHINES_NAMESPACE } from "app/machines/locales";
import { LogoutButton } from "auth/components/LogoutButton";
import { useTranslation } from "react-i18next";
import { Link as ReactLink, useHistory } from "react-router-dom";
import { ReactComponent as Logo } from "./static/Logo.svg";
import { ReactComponent as ProfileIcon } from "./static/Person.svg";
import { NotificationCounterButton } from "app/users";
import { ReactComponent as OverviewIcon } from "./static/Overview.svg";
import { ReactComponent as RealTimeIcon } from "./static/Real-Time.svg";
import { ReactComponent as ChartIcon } from "./static/Chart.svg";
import { ReactComponent as LogsIcon } from "./static/Logs.svg";
import { MenuItem } from "./components/MenuItem";
import { useEffect, useState } from "react";

export const Navbar = () => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const history = useHistory();
  const [location, setLocation] = useState(history.location.pathname);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setLocation(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Flex
      bg="white"
      px={[0, 4, 6]}
      m={0}
      alignItems="center"
      borderBottomRadius={["12px", "24px"]}
      boxShadow="0 0px 20px 5px var(--chakra-colors-secondary-400)">
      <Logo fill="var(--chakra-colors-primary-100)" height="32px" />
      <Spacer />
      <Flex
        width={["100%", "50%"]}
        height={["initial", "100%"]}
        position={["fixed", "initial"]}
        alignItems={["flex-end", "flex-end"]}
        justifyContent={["center", "initial"]}
        bottom={[0, "initial"]}
        bg="background.100"
        borderTopRadius={["12px", "initial"]}
        boxShadow={[
          "0 0px 20px 5px var(--chakra-colors-secondary-400)",
          "initial",
        ]}
        zIndex={[1, "initial"]}
        sx={{
          "& > *": {
            width: "calc(100% / 4)",
          },
        }}>
        <MenuItem
          title={t("navbar.machines")}
          icon={<OverviewIcon />}
          to="/machines"
          active={location === "/" || location === "/machines"}
        />
        <MenuItem
          title={t("navbar.realTime")}
          icon={<RealTimeIcon />}
          to="/realtime"
          active={location.startsWith("/realtime")}
        />
        <MenuItem
          title={t("navbar.analytics")}
          icon={<ChartIcon />}
          to="/analytics"
          active={location.startsWith("/analytics")}
        />
        <MenuItem
          title={t("navbar.logs")}
          icon={<LogsIcon />}
          to="/logs"
          active={location.startsWith("/logs")}
        />
      </Flex>
      <Spacer />
      <Flex>
        <NotificationCounterButton />
        <IconButton
          as={ReactLink}
          variant="ghost"
          aria-label="Profile"
          icon={<ProfileIcon />}
          to="/profile"
        />
        <LogoutButton />
      </Flex>
    </Flex>
  );
};
