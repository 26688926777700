import { PageRequest } from "network/models";

export const stringifyPageRequest = (pageRequest?: PageRequest): string => {
  const params = [];

  if (pageRequest?.page) {
    params.push(`page[number]=${pageRequest?.page}`);
  }
  if (pageRequest?.size) {
    params.push(`page[size]=${pageRequest?.size}`);
  }
  if (pageRequest?.filters) {
    params.push(
      ...pageRequest?.filters.map(
        (it) =>
          `filter[${it.field}]=${it.values
            .map((it) => encodeURIComponent(it))
            .join(",")}`
      )
    );
  }
  if (pageRequest?.sorts) {
    params.push(
      ...pageRequest?.sorts.map((it) => `sort[${it.field}]=${it.value}`)
    );
  }

  return params.length ? `?${params.join("&")}` : "";
};
