import { observer } from "mobx-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useStoreContext } from "store/context";
import { MachineLog } from "../models";
import { ClassNames } from "@emotion/react";
import { DateRangeInput, Panel } from "app/shared";
import { FilterField, SortField } from "network/models";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import moment from "moment";
import { tableStyle } from "app/shared/tables/style";
import { Link as ReactLink } from "react-router-dom";
import {
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Select,
} from "@chakra-ui/react";
import { MachineSelector } from "./MachineSelector";
import { MachineLogTypeLabel } from "./MachineLogTypeLabel";
import debounce from "lodash.debounce";
import { ReactComponent as SearchIcon } from "../static/Search.svg";

const MachineLogsList: React.FC = () => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const { machineStore } = useStoreContext();

  const getInitialFilters = (): FilterField[] => {
    const initialFilters = [
      {
        field: "from",
        values: [moment().utc().add(-7, "days").startOf("day").toISOString()],
      },
      { field: "to", values: [moment().utc().endOf("day").toISOString()] },
    ];

    if (machineStore.defaultMachineInstanceId) {
      initialFilters.push({
        field: "machineInstanceId",
        values: [machineStore.defaultMachineInstanceId],
      });
    }

    return initialFilters;
  };

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<FilterField[]>(getInitialFilters());
  const [sort, setSort] = useState<SortField>({
    field: "timestamp",
    value: "desc",
  });
  const size = 50;
  const fromFilter = filters.find((it) => it.field === "from")?.values?.[0];
  const toFilter = filters.find((it) => it.field === "to")?.values?.[0];
  const machineInstanceIdFilter = filters.find(
    (it) => it.field === "machineInstanceId"
  )?.values?.[0];

  const setSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    const newFilters = filters.filter((it) => it.field !== "terms");

    if (event.target.value) {
      newFilters.push({ field: "terms", values: [event.target.value] });
    }

    setFilters([...newFilters]);
  };

  useEffect(() => {
    machineStore.getMachineLogs(page, size, filters, [sort]);
  }, [machineStore, page, size, filters, sort]);

  return (
    <Panel>
      <Grid
        gridTemplateColumns={["repeat(1, 1fr)", "repeat(4, 1fr)"]}
        gridColumnGap={5}
        mb={5}>
        <InputGroup mb={[3, 0]}>
          <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
          <Input
            type="text"
            placeholder="Search description"
            onChange={debounce(setSearchTerm, 300)}
          />
        </InputGroup>
        <MachineSelector
          placeholder={t("machineLogsList.allMachines")}
          selectedMachineInstanceId={machineInstanceIdFilter}
          onMachineChange={(machineInstance) => {
            const newFilters = filters.filter(
              (it) => it.field !== "machineInstanceId"
            );

            if (machineInstance) {
              newFilters.push({
                field: "machineInstanceId",
                values: [machineInstance.id],
              });
            }

            setFilters([...newFilters]);
          }}
        />
        <Select
          placeholder={t("machineLogsList.type")}
          my={[3, 0]}
          onChange={(event) => {
            const newFilters = filters.filter((it) => it.field !== "type");

            if (event.target.value) {
              newFilters.push({ field: "type", values: [event.target.value] });
            }

            setFilters([...newFilters]);
          }}>
          {["Error", "Warning", "Log"].map((it) => (
            <option key={it} value={it}>
              {it}
            </option>
          ))}
        </Select>
        <DateRangeInput
          defaultFrom={fromFilter ? new Date(fromFilter) : undefined}
          defaultTo={toFilter ? new Date(toFilter) : undefined}
          onChange={(from, to) => {
            const newFilters = filters.filter(
              (it) => it.field !== "from" && it.field !== "to"
            );

            if (from) {
              newFilters.push({ field: "from", values: [from.toISOString()] });
            }
            if (to) {
              newFilters.push({ field: "to", values: [to.toISOString()] });
            }

            setFilters([...newFilters]);
          }}
        />
      </Grid>
      {machineStore.state !== "error" && (
        <ClassNames>
          {({ css, cx }) => (
            <DataTable
              paginatorTemplate="PrevPageLink NextPageLink"
              paginator={true}
              paginatorPosition="both"
              loading={machineStore.state === "pending"}
              lazy={true}
              rows={size}
              value={machineStore.machineLogs}
              first={page * size}
              className={css`
                ${tableStyle}
              `}
              onPage={(e: any) => setPage(e.page)}
              totalRecords={machineStore.totalLogs}
              sortField={sort.field}
              sortOrder={sort.value === "asc" ? 1 : -1}
              onSort={(e) =>
                setSort({
                  field: e.sortField,
                  value: e.sortOrder === 1 ? "asc" : "desc",
                })
              }>
              <Column
                header={t("machineLogsList.description")}
                field="description"
                sortable={true}
              />
              <Column
                header={t("machine")}
                sortable={true}
                sortField="machineInstanceId"
                body={(machineLog: MachineLog) => (
                  <Link
                    as={ReactLink}
                    to={`/realtime/${machineLog.machineInstanceId}`}>
                    {machineLog.machineInstanceId}
                  </Link>
                )}
              />
              <Column
                header={t("machineLogsList.type")}
                field="type"
                sortable={true}
                body={(machineLog: MachineLog) => (
                  <MachineLogTypeLabel
                    type={machineLog.type}
                    justifyContent="center"
                  />
                )}
              />
              <Column
                header={t("machineLogsList.date")}
                sortable={true}
                sortField="timestamp"
                body={(machineLog: MachineLog) =>
                  moment(machineLog.timestamp).format("YYYY-MM-DD HH:mm:ss")
                }
              />
            </DataTable>
          )}
        </ClassNames>
      )}
    </Panel>
  );
};

export default observer(MachineLogsList);
