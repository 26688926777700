import { AppModule } from "AppModule";
import { registerResourceBundle, SUPPORTED_LANGUAGES } from "i18n";
import { AUTH_NAMESPACE } from "./locales";
import { default as EN } from "./locales/en.json";

export class AuthModule implements AppModule {
  setupTranslations() {
    registerResourceBundle(SUPPORTED_LANGUAGES.en, AUTH_NAMESPACE, EN);
  }
}
