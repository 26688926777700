import { Box, Heading, Text, Tooltip } from "@chakra-ui/react";
import { TimeSeriesChart } from "app/shared";
import {
  LineType,
  TimeSeriesChartPoint,
} from "app/shared/charts/TimeSeriesChart";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import {
  MachineDefinition,
  MachineGraph,
  MachineTelemetry,
  Measure,
} from "../models";
import { getMeasurePresentationValue, getMeasureValue } from "../utils";

interface Props {
  graph: MachineGraph;
  telemetry: MachineTelemetry[];
  definition: MachineDefinition;
  measures: Measure[];
  showLastValue: boolean;
  lastTimestamp: string;
  firstTimestamp?: string;
  lineType?: LineType;
}

interface MergedPoint {
  timestamp: string;
  [dataKey: string]: string;
}

/**
 * This method will fill in gaps between points with missing data assuming the last value for that sensor
 * (if there is no reading we assume the last value still applies)
 */
const buildPoints = (
  measures: Measure[],
  dataSources: string[],
  firstTimestamp: string,
  lastTimestamp: string,
  includeFirstLastPoint: boolean
): MergedPoint[] => {
  const data: MergedPoint[] = [];

  measures.forEach((measure) => {
    const timestamp = measure.timestamp;
    const value = measure.value;
    const existingPoint = data.find((it) => it.timestamp === timestamp);

    if (existingPoint) {
      existingPoint[measure.name] = value;
      existingPoint[`${measure.name}-real-value`] = value;
    } else {
      data.push({
        timestamp,
        [measure.name]: value,
        [`${measure.name}-real-value`]: value,
      });
    }
  });

  if (data.length === 0) {
    return [];
  }

  // Fill in all data source values
  const lastValues: Record<string, string> = {};
  data.forEach((el) => {
    dataSources.forEach((sensorId) => {
      if (el[sensorId] === undefined) {
        el[sensorId] = lastValues[sensorId];
      } else {
        lastValues[sensorId] = el[sensorId];
      }
    });
  });

  const dataFiltered = data.filter(
    (it) =>
      new Date(it.timestamp).getTime() >= new Date(firstTimestamp).getTime()
  );

  // Fill first/last point from filtered data or else fallback to the last value before filtering
  if (includeFirstLastPoint) {
    const firstPoint: MergedPoint = {
      timestamp: firstTimestamp,
    };
    const lastPoint: MergedPoint = {
      timestamp: lastTimestamp,
    };

    dataSources.forEach((sensorId) => {
      firstPoint[sensorId] =
        dataFiltered?.[0]?.[sensorId] ?? lastValues[sensorId];
      lastPoint[sensorId] =
        dataFiltered?.[dataFiltered.length - 1]?.[sensorId] ??
        lastValues[sensorId];
    });

    return [firstPoint, ...dataFiltered, lastPoint];
  } else {
    return dataFiltered;
  }
};

export const SensorGraph: React.FC<Props> = ({
  graph,
  telemetry,
  definition,
  measures,
  showLastValue,
  lastTimestamp,
  firstTimestamp,
  lineType,
}) => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const dataSources = graph.dataSources.map((it) => it.sensorId);
  const points = buildPoints(
    measures,
    dataSources,
    firstTimestamp ??
      (graph.lastMinutes > 0
        ? moment().utc().subtract(graph.lastMinutes, "minutes").toISOString()
        : new Date(0).toISOString()),
    lastTimestamp,
    graph.graphType === "Graph"
  );
  const lastValue = points?.[points.length - 1];

  const renderGraph = (points: MergedPoint[], dataSources: string[]) => {
    const graphPoints: TimeSeriesChartPoint[] = [];
    points.forEach((point) => {
      const el: TimeSeriesChartPoint = {
        timestamp: new Date(point.timestamp).getTime(),
      };
      dataSources.forEach((sourceId) => {
        const value = getMeasureValue(point[sourceId]);
        if (value !== undefined) {
          el[sourceId] = value;

          if (point[`${sourceId}-real-value`]) {
            el[`${sourceId}-real-value`] = value;
          }
        }
      });
      graphPoints.push(el);
    });

    const bars = telemetry.map((telemetry) => ({
      dataKey: telemetry.name,
      color:
        graph.dataSources.find((it) => it.sensorId === telemetry.name)?.color ??
        "#DC0000",
      label: `${telemetry.name} (${telemetry.label})`,
      lineType,
    }));

    return (
      <Box height="200px">
        <TimeSeriesChart data={graphPoints} bars={bars} />
      </Box>
    );
  };

  const renderHistory = (
    points: MergedPoint[],
    dataSources: string[],
    graph: MachineGraph,
    telemetry: MachineTelemetry[],
    definition: MachineDefinition
  ) => {
    const historyPoints: MergedPoint[] = [];
    points.forEach((point) => {
      const timestampGroupByMinute = moment(point.timestamp).format(
        "YYYY-MM-DDTHH:mm"
      );

      const existingPoint = historyPoints.find((historyPoint) => {
        if (historyPoint.timestamp === timestampGroupByMinute) {
          // If there is already a point for the sensor make sure to create a new point so it is not replaced
          const pointDataSources = dataSources.filter(
            (sourceId) => point[sourceId]
          );
          const existingDataSource = pointDataSources.find(
            (sourceId) => historyPoint[sourceId] !== undefined
          );

          return existingDataSource ? false : true;
        } else {
          return false;
        }
      });

      if (existingPoint) {
        dataSources.forEach((sourceId) => {
          existingPoint[sourceId] = point[sourceId];
        });
      } else {
        // Skip same value if last one is the same to prevent repeated values from realtime.
        // For widgets with more than one data source, we must check if all other data sources
        // also did not change to safely skip all others.
        const diffDataSources = dataSources.filter((sourceId) => {
          const lastValue = historyPoints?.[historyPoints.length - 1];

          if (
            lastValue === undefined ||
            lastValue[sourceId] !== point[sourceId]
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (diffDataSources.length > 0) {
          const el: MergedPoint = { timestamp: timestampGroupByMinute };
          dataSources.forEach((sourceId) => {
            el[sourceId] = point[sourceId];
          });
          historyPoints.push(el);
        }
      }
    });

    return (
      <Box mt={2}>
        {historyPoints
          .reverse()
          .slice(0, 5)
          .map((point, key) => (
            <Heading
              variant="h7Strong"
              textTransform="uppercase"
              key={key}
              mb={2}>
              <Text as="span" opacity={0.7} fontSize="8px">
                {moment(point.timestamp).format("MM-DD HH:mm")}
                {": "}
              </Text>
              {graph.valueFormatter && graph.valueFormatter.length > 0
                ? graph.valueFormatter.split(" ").map((str, index) => {
                    const dataSourceDefinition = graph.dataSources.find(
                      (it) => it.sensorId === str
                    );

                    // If str matches sensor name print value else the raw text
                    if (dataSourceDefinition) {
                      const value = point[str]
                        ? getMeasurePresentationValue(
                            point[str],
                            telemetry.find(
                              (telemetry) => telemetry.name === str
                            )!!,
                            definition
                          )
                        : "-";

                      return (
                        <Text key={index} as="span" mr={1}>
                          {value}
                        </Text>
                      );
                    } else {
                      return (
                        <Text key={index} as="span" mr={1}>
                          {str}
                        </Text>
                      );
                    }
                  })
                : dataSources
                    .filter((sourceId) => point[sourceId])
                    .map((sourceId) =>
                      getMeasurePresentationValue(
                        point[sourceId],
                        telemetry.find(
                          (telemetry) => telemetry.name === sourceId
                        )!!,
                        definition
                      )
                    )
                    .join(" - ")}
            </Heading>
          ))}
      </Box>
    );
  };

  const renderLastValue = (
    lastValue: MergedPoint,
    dataSources: string[],
    graph: MachineGraph,
    telemetry: MachineTelemetry[],
    definition: MachineDefinition
  ) => {
    return (
      <Heading
        variant="h3"
        fontSize={["20px", "28px"]}
        textTransform="uppercase"
        wordBreak="break-word">
        {graph.valueFormatter && graph.valueFormatter.length > 0
          ? graph.valueFormatter.split(" ").map((str, index) => {
              const dataSourceDefinition = graph.dataSources.find(
                (it) => it.sensorId === str
              );

              // If str matches sensor name print value else the raw text
              if (dataSourceDefinition) {
                const value = lastValue[str]
                  ? getMeasurePresentationValue(
                      lastValue[str],
                      telemetry.find((telemetry) => telemetry.name === str)!!,
                      definition
                    )
                  : "-";

                return (
                  <Tooltip key={index} label={str}>
                    <Text
                      as="span"
                      color={dataSourceDefinition?.color ?? "primary.100"}
                      mr={3}>
                      {value}
                    </Text>
                  </Tooltip>
                );
              } else {
                return (
                  <Tooltip key={index} label={str}>
                    <Text as="span" mr={3}>
                      {str}
                    </Text>
                  </Tooltip>
                );
              }
            })
          : dataSources
              .filter((sourceId) => lastValue[sourceId])
              .map((sourceId) => {
                const value = getMeasurePresentationValue(
                  lastValue[sourceId],
                  telemetry.find((telemetry) => telemetry.name === sourceId)!!,
                  definition
                );
                const dataSourceDefinition = graph.dataSources.find(
                  (it) => it.sensorId === sourceId
                );

                return (
                  <Tooltip key={sourceId} label={sourceId}>
                    <Text
                      as="span"
                      color={dataSourceDefinition?.color ?? "primary.100"}
                      mr={3}>
                      {value}
                    </Text>
                  </Tooltip>
                );
              })}
      </Heading>
    );
  };

  return (
    <Box>
      <Heading variant="h6" textTransform="uppercase">
        {graph.label}
      </Heading>
      {points.length ? (
        <>
          {showLastValue &&
            lastValue &&
            renderLastValue(
              lastValue,
              dataSources,
              graph,
              telemetry,
              definition
            )}
          {graph.graphType === "Graph" && renderGraph(points, dataSources)}
          {graph.graphType === "History" &&
            renderHistory(points, dataSources, graph, telemetry, definition)}
        </>
      ) : (
        <Box mt={2}>
          <Heading variant="h7Strong" textTransform="uppercase">
            {t("sensorGraph.noData")}
          </Heading>
        </Box>
      )}
    </Box>
  );
};
