import { Input } from "@chakra-ui/input";
import { Box } from "@chakra-ui/layout";
import moment from "moment";
import React, { useState } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { OutsideClickWrapper } from "../OutsideClickWrapper";

interface Props {
  onChange: (from: Date, to: Date) => void;
  defaultFrom?: Date;
  defaultTo?: Date;
  placeholder?: string;
}

export const DateRangeInput: React.FC<Props> = ({
  onChange,
  defaultFrom,
  defaultTo,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [from, setFrom] = useState<Date | undefined | null>(defaultFrom);
  const [to, setTo] = useState<Date | undefined | null>(defaultTo);
  const value = `${from ? moment(from).format("DD MMM YYYY") : ""}${
    from && to ? " - " : ""
  }${to ? moment(to).format("DD MMM YYYY") : ""}`;

  const handleDayClick = (day: Date) => {
    const range = DateUtils.addDayToRange(day, { from, to });
    setFrom(range.from);
    setTo(range.to);

    if (range.from && range.to) {
      onChange(
        moment(range.from).utc().startOf("day").toDate(),
        moment(range.to).utc().endOf("day").toDate()
      );
    }
  };

  return (
    <OutsideClickWrapper
      position="relative"
      isOpen={isOpen}
      callback={() => setIsOpen(false)}>
      <Input
        type="text"
        isReadOnly
        value={value.length ? value : placeholder}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <Box position="absolute" background="white" zIndex={1}>
          <DayPicker
            selectedDays={{ from, to }}
            disabledDays={{ after: new Date() }}
            onDayClick={handleDayClick}
          />
        </Box>
      )}
    </OutsideClickWrapper>
  );
};
