import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useStoreContext } from "store/context";
import { MachineInstance, MachineInstanceStatus } from "../models";
import { ClassNames } from "@emotion/react";
import { Map, Marker, Panel } from "app/shared";
import { SortField } from "network/models";
import { ReactComponent as RealTimeIcon } from "../static/Real-Time.svg";
import { ReactComponent as AnalyticsIcon } from "../static/Chart.svg";
import { ReactComponent as SearchIcon } from "../static/Search.svg";
import { Link as ReactLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MACHINES_NAMESPACE } from "../locales";
import debounce from "lodash.debounce";
import moment from "moment";
import { tableStyle } from "app/shared/tables/style";
import { MachineStatusLabel } from "./MachineStatusLabel";

const MachineInstancesList: React.FC = () => {
  const { t } = useTranslation(MACHINES_NAMESPACE);
  const history = useHistory();
  const { machineStore } = useStoreContext();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<SortField>({ field: "id", value: "asc" });
  const [searchTerm, _setSearchTerm] = useState("");
  const size = 50;

  useEffect(() => {
    const filters = [{ field: "lastUpdate", values: ["true"] }];

    if (searchTerm.length > 0) {
      filters.push({ field: "terms", values: [searchTerm] });
    }

    machineStore.getMachineInstances(page, size, filters, [sort]);
  }, [machineStore, page, size, sort, searchTerm]);

  const setSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    _setSearchTerm(event.target.value);
  };

  const getStatusColor = (status: MachineInstanceStatus) => {
    if (status === "Online") {
      return "#57CC64";
    } else if (status === "Cutting") {
      return "#FA9900";
    } else if (status === "Offline") {
      return "#DC0000";
    }

    return "#B5B5B5"; // Unavailable
  };

  const machineInstancePositions = machineStore.machineInstances
    .filter((it) => it.latitude !== undefined && it.longitude !== undefined)
    .map((it) => ({
      id: it.id,
      lat: it.latitude,
      lng: it.longitude,
      color: getStatusColor(it.status),
    }));

  return (
    <Panel>
      <InputGroup>
        <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
        <Input
          type="text"
          placeholder="Search"
          onChange={debounce(setSearchTerm, 300)}
        />
      </InputGroup>

      {machineStore.state !== "error" && (
        <Tabs align="center" mt={5}>
          <TabList>
            <Tab
              _selected={{ borderColor: "primary.100" }}
              textTransform="uppercase">
              <Heading variant="h7Strong" py={1}>
                {t("machineInstancesList.listView")}
              </Heading>
            </Tab>
            <Tab
              _selected={{ borderColor: "primary.100" }}
              textTransform="uppercase">
              <Heading variant="h7Strong" py={1}>
                {t("machineInstancesList.mapView")}
              </Heading>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <ClassNames>
                {({ css, cx }) => (
                  <DataTable
                    paginatorTemplate="PrevPageLink NextPageLink"
                    paginator={true}
                    loading={machineStore.state === "pending"}
                    lazy={true}
                    rows={size}
                    value={machineStore.machineInstances}
                    first={page * size}
                    className={css`
                      ${tableStyle}
                    `}
                    onPage={(e: any) => setPage(e.page)}
                    totalRecords={machineStore.totalInstances}
                    sortField={sort.field}
                    sortOrder={sort.value === "asc" ? 1 : -1}
                    onSort={(e) =>
                      setSort({
                        field: e.sortField,
                        value: e.sortOrder === 1 ? "asc" : "desc",
                      })
                    }>
                    <Column header={t("machine")} field="id" sortable={true} />
                    <Column
                      header={t("client")}
                      field="client.name"
                      sortable={true}
                      sortField="clientId"
                    />
                    <Column
                      header={t("lastUpdate")}
                      sortable={true}
                      sortField="lastUpdate"
                      body={(machineInstance: MachineInstance) =>
                        moment(machineInstance.lastUpdate).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      }
                    />
                    <Column header={t("country")} field="site.country" />
                    <Column header={t("location")} field="site.location" />
                    <Column
                      header={t("status")}
                      field="status"
                      sortable={true}
                      body={(machineInstance: MachineInstance) => (
                        <MachineStatusLabel
                          status={machineInstance.status}
                          justifyContent="center"
                        />
                      )}
                    />
                    <Column
                      body={(machineInstance: MachineInstance) => (
                        <Stack direction="row" spacing={2}>
                          <IconButton
                            variant="secondary"
                            as={ReactLink}
                            aria-label="Real time"
                            icon={<RealTimeIcon />}
                            to={`/realtime/${machineInstance.id}`}
                            size="sm"
                          />
                          <IconButton
                            variant="secondary"
                            as={ReactLink}
                            aria-label="Analytics"
                            icon={<AnalyticsIcon />}
                            to={`/analytics/${machineInstance.id}`}
                            size="sm"
                          />
                        </Stack>
                      )}
                    />
                  </DataTable>
                )}
              </ClassNames>
            </TabPanel>
            <TabPanel>
              <Map
                center={
                  machineInstancePositions[0] ?? {
                    lat: 38.7436883,
                    lng: -9.1952226,
                  }
                }
                zoom={8}
                mapContainerStyle={{
                  width: "100%",
                  height: "75vh",
                }}>
                {machineInstancePositions.map((instance, key) => (
                  <Marker
                    key={key}
                    lat={instance.lat}
                    lng={instance.lng}
                    color={instance.color}
                    label={instance.id}
                    onClick={() => history.push(`/realtime/${instance.id}`)}
                  />
                ))}
              </Map>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Panel>
  );
};

export default observer(MachineInstancesList);
