import React from "react";
import { Flex, FlexProps, Image, Text } from "@chakra-ui/react";
import GsmBar0 from "../static/GsmBar0.png";
import GsmBar1 from "../static/GsmBar1.png";
import GsmBar2 from "../static/GsmBar2.png";
import GsmBar3 from "../static/GsmBar3.png";
import GsmBar4 from "../static/GsmBar4.png";
import GsmBar5 from "../static/GsmBar5.png";
import GsmType0 from "../static/GsmType0.png";
import GsmType1 from "../static/GsmType1.png";
import GsmType2 from "../static/GsmType2.png";
import GsmType4 from "../static/GsmType4.png";
import GsmType5 from "../static/GsmType5.png";

interface Props extends FlexProps {
  type?: string;
  signal?: string;
  operator?: string;
}

export const MachineSignalLabel: React.FC<Props> = ({
  type,
  signal,
  operator,
  ...rest
}) => {
  const getTypeIconSrc = (value: string) => {
    if (value === "1") {
      return GsmType1;
    } else if (value === "2") {
      return GsmType2;
    } else if (value === "5") {
      return GsmType5;
    } else if (value === "4") {
      return GsmType4;
    } else {
      return GsmType0;
    }
  };
  const getSignalIconSrc = (value?: string) => {
    if (value === "1") {
      return GsmBar1;
    } else if (value === "2") {
      return GsmBar2;
    } else if (value === "3") {
      return GsmBar3;
    } else if (value === "4") {
      return GsmBar4;
    } else if (value === "5") {
      return GsmBar5;
    } else {
      return GsmBar0;
    }
  };

  return (
    <Flex alignItems="flex-end" {...rest}>
      {type && <Image src={getTypeIconSrc(type)} width="24px" />}
      <Image src={getSignalIconSrc(signal)} width="24px" />
      {operator && (
        <Text as="span" ml={1}>
          {operator.replaceAll('"', "")}
        </Text>
      )}
    </Flex>
  );
};
